import React, { useEffect, useState, useContext } from "react";
import { GiSettingsKnobs } from "react-icons/gi";
import { HiMagnifyingGlass } from "react-icons/hi2";
import {
  InputGroup,
  Dropdown,
  Popover,
  OverlayTrigger,
  Form,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import TenantsList from "../components/tenants/TenantsList";
import NonTenantsList from "../components/tenants/NonTenantsList";
import { Ucontext } from "../context/Ucontext";
import Loading from "../components/loader/Loading";
import ReactPaginate from "react-paginate";
import { MdNavigateNext } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";

const Tenants = () => {
  const { adminDispatch, getSingleUser, adminState, getAllTenants, getTenantJoinedFromList } =
    useContext(Ucontext);
  const { totalTenants, totalProperties } = adminState;

  const [sortColumn, setSortColumn] = useState(null);
  const [sortType, setSortType] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortBy, setSortBy] = useState("Latest");
  const [filter, setFilter] = useState({
    digital_verification: "",
    bank_verified: "",
    have_guarantor: "",
    guarantor_scored: "",
    linked_property: "",
    joined_from: ""
  });

  const location = useLocation();
  // console.log(location.pathname);

  const [routeName, setRouteName] = useState();
  const [tenants, setTenants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [joinedFromList, setJoinedFromList] = useState([]);

  useEffect(() => {
    loadTenants(currentPage, searchVal, sortBy, filter);
  }, [currentPage, searchVal, sortBy, filter, sortColumn, sortType]);

  useEffect(() => {
    checkRented();
  }, [totalTenants]);

  useEffect(() => {
    fetchJoinedFromList();
  }, []);

  const loadTenants = async (currentPage, searchVal, sortBy, filters) => {
    setLoading(true);
    const {
      digital_verification = "",
      bank_verified = "",
      have_guarantor = "",
      guarantor_scored = "",
      linked_property = "",
      joined_from = ""
    } = filter;

    let tenants = await getAllTenants(
      currentPage,
      searchVal,
      "rented",
      sortColumn,
      digital_verification,
      bank_verified,
      have_guarantor,
      guarantor_scored,
      linked_property,
      sortType,
      joined_from
    );
    if (tenants.status == "Success") {
      await adminDispatch({
        type: "TENANTS_LOADED",
        payload: tenants?.tenants,
      });
      setTotalPages(tenants.totalPages);
    }
    setLoading(false);
  };

  const fetchJoinedFromList = async () => {
    try {

      const response = await getTenantJoinedFromList();
      const data = response.data;
      if (data && data?.success_res?.data) {
        setJoinedFromList(data.success_res.data)
      }
    }
    catch (error) {
      console.log(error)
    }
  }

  const checkRented = async () => {
    setTenants(totalTenants);
  };

  const handleSort = (sortType) => {
    console.log("HANDLESORT", sortType);
    setSortBy(sortType);
    setcurrentPage(1);
  };

  let interval;
  const handleSearch = (e) => {
    clearTimeout(interval);
    interval = setTimeout(() => {
      setSearchVal(e.target.value);
      setcurrentPage(1);
    }, 500);
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0) {
      setcurrentPage(newPage);
    }
  };
  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    setcurrentPage(event.selected + 1);
  };

  const handleClickFilter = (e) => {
    console.log(e.target.name);
    setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setcurrentPage(1);
  };

  const handleSorting = (name) => {
    if (name == sortColumn) {
      setSortType((prev) => (prev == "asc" ? "desc" : "asc"));
    } else {
      setSortType("asc");
    }
    console.log(name);
    setcurrentPage(1);
    setSortColumn(name);
  };

  return (
    <>
      {loading ? <Loading /> : null}
      <div className="tenats-main">
        <div className="tenats-header">
          <div className="header_table d-flex justify-content-between align-items-center">
            <h1>Tenants</h1>
          </div>
          <div className="d-flex justify-content-around tenantslist-admin-filter">
            <div className="tenants-filter-box">
              <div>
                <h5>Digital Verification</h5>
                {/* <select
                  name="digital_verification"
                  id="digital_verification"
                  onChange={handleClickFilter}
                >
                  <option value="">All</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select> */}
                <Form.Select
                  name="digital_verification"
                  id="digital_verification"
                  onChange={handleClickFilter}
                >
                  <option value="">All</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </Form.Select>
              </div>
              <div>
                <h5>Bank Verified</h5>
                {/* <select
                  name="bank_verified"
                  id="bank_verified"
                  onChange={handleClickFilter}
                >
                  <option value="">All</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select> */}
                <Form.Select
                  name="bank_verified"
                  id="bank_verified"
                  onChange={handleClickFilter}
                >
                  <option value="">All</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </Form.Select>
              </div>
              <div>
                <h5>Have Guarantor</h5>
                {/* <select
                  name="have_guarantor"
                  id="have_guarantor"
                  onChange={handleClickFilter}
                >
                  <option value="">All</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select> */}
                <Form.Select
                  name="have_guarantor"
                  id="have_guarantor"
                  onChange={handleClickFilter}
                >
                  <option value="">All</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </Form.Select>
              </div>
              <div>
                <h5>Guarantor Scored</h5>
                {/* <select
                  name="guarantor_scored"
                  id="guarantor_scored"
                  onChange={handleClickFilter}
                >
                  <option value="">All</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select> */}
                <Form.Select
                  name="guarantor_scored"
                  id="guarantor_scored"
                  onChange={handleClickFilter}
                >
                  <option value="">All</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </Form.Select>
              </div>
              <div>
                <h5>Linked Property</h5>
                {/* <select
                  name="linked_property"
                  id="linked_property"
                  onChange={handleClickFilter}
                >
                  <option value="">All</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select> */}
                <Form.Select
                  name="linked_property"
                  id="linked_property"
                  onChange={handleClickFilter}
                >
                  <option value="">All</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </Form.Select>
              </div>
              <div>
                <h5>Joined From</h5>
                <Form.Select
                  name="joined_from"
                  id="joined_from"
                  onChange={handleClickFilter}
                  className="joined_from_select"
                >
                  <option value="">All</option>
                  {
                    Array.isArray(joinedFromList) && joinedFromList.map(joinedFrom => <option value={joinedFrom.referredName}>{joinedFrom.referredName}</option>)
                  }
                </Form.Select>
              </div>
            </div>
            <div className="searchbar_table ">
              <div>
                <InputGroup className="input_table_header">
                  <InputGroup.Text id="basic-addon1">
                    <HiMagnifyingGlass />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="basic-addon1"
                    // value={searchVal}
                    onChange={handleSearch}
                  />
                </InputGroup>
              </div>
              {/* <OverlayTrigger trigger="click" placement="bottom"
            overlay={
              <Popover id="popover-basic">
                <Popover.Body>
                  <div>
                    <p>Sort By</p>
                    <div className='popover_buttons'>
                      <Dropdown onSelect={handleSort}>
                        <Dropdown.Toggle style={{ width: "226px", border: '1px solid #c8c8c8ad', borderRadius: '12px' }} variant="light" id="dropdown-basic">
                          {sortBy}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item eventKey="Latest" >Latest</Dropdown.Item>
                          <Dropdown.Item eventKey="Oldest" >Oldest</Dropdown.Item>
                          <Dropdown.Item eventKey="Name [A-Z]" >Name [A-Z]</Dropdown.Item>
                          <Dropdown.Item eventKey="Name [Z-A]" >Name [Z-A]</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </Popover.Body>
              </Popover>}>
            <button>
              <GiSettingsKnobs size={22} color='var(--dark)' />
            </button>
          </OverlayTrigger> */}
            </div>
          </div>
        </div>
        <TenantsList
          loadTenants={loadTenants}
          tenants={tenants}
          sortColumn={sortColumn}
          setSortColumn={setSortColumn}
          sortType={sortType}
          setSortType={setSortType}
          handleSorting={handleSorting}
          currentPage={currentPage}
          searchVal={searchVal}
          sortBy={sortBy}
          filter={filter}
        />
        {/* <div className="">
              <button
                onClick={() => handlePageChange(+currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span>{`Page ${currentPage} of ${totalPages}`}</span>
              <button
                onClick={() => handlePageChange(+currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div> */}
        <ReactPaginate
          nextLabel={<MdNavigateNext />}
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={totalPages}
          previousLabel={<GrFormPrevious />}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
          forcePage={currentPage - 1}
        />
      </div>
    </>
  );
};

export default Tenants;

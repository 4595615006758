import { useEffect, useState } from "react";
import { Form, InputGroup, Table } from "react-bootstrap";
import { fetchTranslations, updateTranslations } from "../context/functions/AdminFunction";
import { toast } from "react-toastify";
import Loading from "../components/loader/Loading";
const TranslationList = (props) => {

    const [translations, setTranslations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editing, setEditing] = useState({});
    const [btnDisable, setBtnDisable] = useState(false);

    useEffect(() => {
        const getTranslationsFunc = async () => {
            setLoading(true);
            try {
                const response = await fetchTranslations();
                let data = response?.data;
                if (data && data?.success_res?.data) {
                    setTranslations(data.success_res.data)
                }
            }
            catch (error) {

            }
            setLoading(false);
        };

        getTranslationsFunc();
    }, []);

    const handleEditChange = (key, field, value) => {
        setEditing((prev) => {
            return ({
                ...prev,
                [key]: {
                    ...prev[key],
                    [field]: value,
                },
            })
        });
    };

    const saveTranslation = async (key) => {
        let { en, es } = editing[key] || {};

        if (en === undefined && es === undefined) {
            toast.error("Please change at least one value");
        }
        else {

            const translation = translations.find((translation) => translation.key === key);
            if (en === undefined || es === undefined) {
                if (en === undefined)
                    en = translation.en;
                if (es === undefined)
                    es = translation.es;
            }

            if (en === translation.en && es === translation.es) {
                toast.error("Please change at least one value");
            }
            else if (en === "" || es === "") {
                toast.error("Value can not empty");
            }
            else {
                setBtnDisable(true);
                const response = await updateTranslations({ key, enValue: en, esValue: es });
                setTranslations((prev) =>
                    prev.map((item) =>
                        item.key === key
                            ? { ...item, en: en || item.en, es: es || item.es, missing: false }
                            : item
                    )
                );
                setEditing((prev) => {
                    const updated = { ...prev };
                    delete updated[key];
                    return updated;
                });
                if (response?.data?.message) {
                    toast.success(response.data.message);
                }

                setBtnDisable(false);
            }
        };
    }

    const Content = ({ keyVal, missing }) => {

        const charLimit = 60;

        return (
            <>
                {keyVal.slice(0, charLimit)}
                {keyVal.length > charLimit ? "..." : ""}
                {missing && <span style={{ color: 'red' }}> (Missing)</span>}
            </>
        )
    };


    return (
        <>
            {loading ? <Loading /> : null}
            <div className="header_table d-flex justify-content-between align-items-center">
                <h1>Translation</h1>
            </div>
            <Table responsive hover>
                <thead className="patentiallanlord-admin-head">
                    <th>Key</th>
                    <th>English</th>
                    <th>Spanish</th>
                    <th>Actions</th>
                </thead>
                <tbody>
                    {translations.map(({ key, en, es, missing }) => (
                        <tr key={key}>
                            <td width="200">
                                <Content keyVal={key} missing={missing} />
                            </td>
                            <td>
                                <InputGroup className="input_table_header">
                                    <Form.Control
                                        type="text"
                                        value={editing[key]?.en ?? en}
                                        onChange={(e) => handleEditChange(key, 'en', e.target.value)}
                                    />
                                </InputGroup>
                            </td>
                            <td>
                                <InputGroup className="input_table_header">
                                    <Form.Control
                                        type="text"
                                        value={editing[key]?.es ?? es}
                                        onChange={(e) => handleEditChange(key, 'es', e.target.value)}
                                    />
                                </InputGroup>
                            </td>
                            <td>
                                <button type="button" className="btn-save" onClick={() => saveTranslation(key)} disabled={btnDisable}>Save</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    )
}

export default TranslationList
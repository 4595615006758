import React, { useContext, useEffect, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import countries from "i18n-iso-countries";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Accordion,
  Button,
  Card,
  Col,
  Modal,
  Row,
  Spinner,
  Stack,
} from "react-bootstrap";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import tickNew from "../icons/tick-new.svg";
import exclamation from "../icons/exclamation.svg";
import crossNew from "../icons/cross-new.svg";
import { toast } from "react-toastify";
import axios from "axios";
import countryList from "country-list";
import { MdInfoOutline } from "react-icons/md";
import { VscEye } from "react-icons/vsc";
import moment from "moment";
import { RiDeleteBin6Line, RiDownloadLine } from "react-icons/ri";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import {
  deleteTenantDoc,
  fetchTenantDocs,
  getTenantKycDocs,
  getTenantRentedProperties,
  loadSharedProfiles,
  uploadFileData,
} from "../context/functions/AdminFunction";
import { BASE_URL } from "../config";
import Form from "react-bootstrap/Form";
import locationicon from "../icons/location.png";
import ProfileInfoSection from "../components/TenantHome/ProfileInfoSection";
import ProfessionalAcademicSection from "../components/TenantHome/ProfessionalAcademicSection";
import SummarySection from "../components/TenantHome/SummarySection";
import { Ucontext } from "../context/Ucontext";
import { constants } from "../utils/constants";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

// defaultPropertyLink,propList,propertyLink from props

const UserScoreProfile = () => {
  const defaultPropertyLink = [];

  const location = useLocation();
  const { authState } = useContext(Ucontext);
  const { user: adminUser } = authState;

  const [fileData, setFileData] = useState(null);
  const [show, setShow] = useState(false);
  const [fileType, setFileType] = useState("");
  const user = location.state;
  console.log(user?._id);

  const [deleteDocumentModal, setDeleteDocumentModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [fullProfileShared, setFullProfileShared] = useState([]);
  const [currentActiveAccordion, setCurrentActiveAccordion] = useState(0);
  const [countProp, setCountProp] = useState(0);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [ident_all, setIdent_all] = useState(false);

  const handleAccordionSelect = (eventKey) => {
    setCurrentActiveAccordion(parseInt(eventKey));
  };
  const _t = (string) => {
    return string;
  };

  const haveValue = (val) => {
    if (
      val !== "" &&
      val !== "undefined" &&
      val !== undefined &&
      val !== null &&
      val !== "null"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const navigate = useNavigate();

  const [buttonDisable, setButtonDisable] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [tenantDocsData, setTenantDocsData] = useState(null);
  const [ieScore, setIeScore] = useState(0);

  //extracting url parameters
  const queryParameters = new URLSearchParams(window.location.search);
  const error1 = queryParameters.get("error");
  const error_message = queryParameters.get("error_reason");
  const account_verification_report_id = queryParameters.get(
    "account_verification_report_id"
  );
  const transaction_report_id = queryParameters.get("transaction_report_id");

  useEffect(() => {
    //console.log("kyc page -----", account_verification_report_id);

    if (error1) {
      toast.error(_t("Bank Details cannot be fetched!"));
    }
    if (account_verification_report_id && transaction_report_id) {
      let userid = user?._id;
      if (!userid) {
        toast.error(_t("User Not Authorized"));
      } else {
        //console.log("Calculate Score============>");
        calculateScore(
          userid,
          account_verification_report_id,
          transaction_report_id
        );
      }
    }
  }, [account_verification_report_id]);

  const calculateScore = async (
    userid,
    accountverificationid,
    transactionreportid
  ) => {
    //console.log("payment score calculations");
    // setBankDiv("kyc-loading");
    await clearPaymentData(userid);
    calculatePaymentScore(userid, accountverificationid, transactionreportid);
  };

  const clearPaymentData = async (tenantid) => {
    //console.log("Clearing Payment Data ===========>> ",);
    return new Promise((resolve) => {
      axios
        .delete(`${BASE_URL}/tenants/algoan-info?tenantid=${tenantid}`)
        .then((res) => {
          //console.log(res);
          //console.log("previous data cleared");
          return resolve(true);
        })
        .catch((err) => {
          let err_res = err;
          toast.error(err_res.message);
          return resolve(null);
        });
    });
  };

  const calculatePaymentScore = async (
    userid,
    accountverificationid,
    transactionreportid
  ) => {
    //
    await axios
      .get(
        `${BASE_URL}/tenants/calculatescore?tenantid=${userid}&accountverificationid=${accountverificationid}&transactionreportid=${transactionreportid}`
      )
      .then((res) => {
        let serverResponse = res.data;
        //console.log(serverResponse);
        if (serverResponse.status !== "success") {
          if (serverResponse.status == "pending") {
          } else {
          }
        }
        if (serverResponse.status === "success") {
          toast.success(_t("Bank Connected Successfully!"));

          // setBankDiv("kyc-loaded");
        }
        //
      })
      .catch((err) => {
        let err_res = err;
        //
        //console.log(err_res);
      });
  };

  const countries1 = countryList.getData();
  const getAlpha3Code = (countryName) => {
    const country = countries1.find(
      (c) => c.name.toLowerCase() === countryName
    );
    return country ? countries.alpha2ToAlpha3(country.code) : "Not found";
  };

  const pathcolor = "";
  const [progressColor, setProgressColor] = useState("#F27833");

  const [error, setError] = useState(false);
  const [offers, setOffers] = useState([]);
  const [scoreError, setScoreError] = useState({});
  const [scoreLoading, setScoreLoading] = useState(false);
  const [Bankshow, BanksetShow] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [userKycInfo, setUserKycInfo] = useState({});
  const [landlords, setAllLandlords] = useState([]);
  const [landlordsOrig, setAllLandlordsOrig] = useState([]);
  const [recordedSalary, setRecordedSalary] = useState({
    salary: 0,
    matched: 0,
  });
  //console.log("RECORDED SALARY = ", recordedSalary);
  const [isEmployeementDataSet, setIsEmployeementDataSet] = useState(false);
  const [haveGuarantor, setHaveGuarantor] = useState(false);

  const loadLandlords = async () => {
    const tenantid = user?._id;
    await axios
      .get(`${BASE_URL}/landlords/alllandlords/main?tenantid=${tenantid}`, {
        headers: { "Accept-Language": localStorage.getItem("i18nextLng") },
      })
      .then((res) => {
        //console.log(res);
        setAllLandlords(res.data.landlords);
        setAllLandlordsOrig(res.data.landlords);
      })
      .catch((err) => {
        //alert(err.message)
        console.log(err);
      });
    return true;
  };

  const loadProfilesShared = async (tenantId) => {
    try {
      const response = await loadSharedProfiles(tenantId);
      if (haveValue(response)) setFullProfileShared(response);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (haveValue(user?._id)) {
      console.log("HAVE USER VALUE", user?._id);
      loadProfilesShared(user?._id);
    }
  }, [user?._id]);

  useEffect(() => {
    loadLandlords();

    loadOffers();
    if (!user?.analysisCompleted && user?.algoanAnalysis) {
      getAnalysisDetails();
      setScoreLoading(true);
    }

    if (!user?.onfido_kyc_success) {
      getKycReport(user?._id);
    }

    if (user?.employment_status == "employed") {
      if (user?.name_of_company && user?.position && user?.type_of_employee) {
        setIsEmployeementDataSet(true);
      }
    }

    if (
      user?.have_gurantor == "no" ||
      user?.have_gurantor == null ||
      user?.haveGuarantor == false
    ) {
      setHaveGuarantor(false);
    } else {
      setHaveGuarantor(true);
    }
    fetchFileData();
  }, []);

  useEffect(() => {
    checkSalary();
    getkycDetails();
  }, []);
  // }, [kycuserinfo]);

  useEffect(() => {
    checkAllMatches();
    calculateIeScore();
  }, [user, userKycInfo]);

  const calculateIeScore = () => {
    var totalScore = (user?.paymentScores?.creditScore?.value || 0) / 2;

    if (checkIeDetails() && user?.is_university) {
      if (totalScore >= 600) {
        totalScore += 200;
      } else {
        totalScore += 400;
      }
    }
    if (totalScore > 850) {
      totalScore = 850;
    }
    setIeScore(totalScore);
  };

  const checkSalary = () => {
    if (haveValue(user?.gurantor_id)) {
      if (user?.gurantor_id?.algoanIncome) {
        let monthlyIncome = parseInt(
          user?.gurantor_id?.algoanIncome?.monthlyAmount
        );
        let recorded_annual_salary = monthlyIncome * 12;

        let userIncome = parseInt(
          haveValue(user?.gurantor_id?.current_annual_income)
            ? user?.gurantor_id?.current_annual_income
            : 0
        );
        let salaryMatch = Math.round(
          (recorded_annual_salary / userIncome) * 100
        );

        setRecordedSalary({
          ...recordedSalary,
          matched: salaryMatch,
          salary: recorded_annual_salary,
        });
      }
    } else {
      if (user.algoanIncome) {
        let monthlyIncome = parseInt(user.algoanIncome?.monthlyAmount);
        let recorded_annual_salary = monthlyIncome * 12;

        let userIncome = parseInt(
          haveValue(user.current_annual_income) ? user.current_annual_income : 0
        );
        let salaryMatch = Math.round(
          (recorded_annual_salary / userIncome) * 100
        );

        setRecordedSalary({
          ...recordedSalary,
          matched: salaryMatch,
          salary: recorded_annual_salary,
        });
      }
    }
  };

  const getkycDetails = () => {
    let userid = user?._id;
    if (user?.onfido_kyc_success) {
      //// console.log("kyc has been done");
      loadKycReport(userid);
    } else {
      //// console.log("kyc has  been done but result has not been fetched");
      getKycReport(userid);
    }
  };

  const loadKycReport = async (tenantid) => {
    await axios
      .get(`${BASE_URL}/tenants/getkycreport/${tenantid}`, {
        headers: { "Accept-Language": localStorage.getItem("i18nextLng") },
      })
      .then((res) => {
        //// console.log(res);
        //toast.success("KYC Report Fetched");
        setUserKycInfo(res.data.kycuser);
      })
      .catch((err) => {
        //console.log(err);
        //toast.error("Kyc Report Not Fetched")
      });
  };

  const getKycReport = async (tenantid) => {
    let myInterval = setInterval(async () => {
      await axios
        .get(`${BASE_URL}/tenants/getkycstatus/${tenantid}`, {
          headers: { "Accept-Language": localStorage.getItem("i18nextLng") },
        })
        .then((res) => {
          ////  console.log(res);
          if (res.data.status === "success") {
            //toast.success("KYC Report Fetched")
            setUserKycInfo(res.data.kycuser);
            clearInterval(myInterval);
          } else {
            clearInterval(myInterval);
          }
        })
        .catch((err) => {
          //console.log(err);
          //toast.error("Kyc Report Not Fetched")
          clearInterval(myInterval);
        });
    }, 3000);
  };

  const checkCompanyname = () => {
    let companyname = user?.name_of_company;
    if (user?.algoanIncome?.employers.length !== 0 && user?.algoanIncome) {
      for (let company of user?.algoanIncome?.employers) {
        if (companyname === company) {
          return <img src={tickNew} alt="" />;
        }
      }
    } else {
      return <img src={crossNew} alt="" />;
    }
  };

  const getAnalysisDetails = async (req, res) => {
    let userid = user?._id;

    let myInterval = setInterval(async () => {
      //console.log("interval started running");

      await axios
        .get(`${BASE_URL}/tenants/checkmyanalysis/${userid}`, {
          headers: { "Accept-Language": localStorage.getItem("i18nextLng") },
        })
        .then((res) => {
          //// console.log(res);
          setScoreLoading(false);
          clearInterval(myInterval);
          //// console.log("interval stopped");
        })
        .catch((err) => {
          setScoreLoading(false);
          //// console.log(err.response.data);
          setScoreError(err.response.data);
          clearInterval(myInterval);
          return;
        });
    }, 4000);
  };

  const loadOffers = async () => {
    let url = `${BASE_URL}/offers/`;
    await axios
      .get(url, {
        headers: { "Accept-Language": localStorage.getItem("i18nextLng") },
      })
      .then((res) => {
        setOffers(res.data.offers);
        //// console.log(res);
      })
      .catch((err) => {
        setError(true);
        //console.log(err);
      });
  };

  const verificationNotDone = () => {
    return (
      <ul className="infodorpdown scrorebardrop">
        <li>
          <button className="iconinfo">
            <img src={crossNew} alt="" />
          </button>{" "}
        </li>
        <ul className="tooltip">
          {/* <li>1. Irregular Income</li> */}
          <li>{_t("Digital Verification Not Completed.")}.</li>
        </ul>
      </ul>
    );
  };
  const verificationInProgress = () => {
    return (
      <ul className="infodorpdown scrorebardrop">
        <li>
          <button className="iconinfo">
            <img src={exclamation} alt="" />
          </button>{" "}
        </li>
      </ul>
    );
  };

  const checkName = () => {
    let fname = user?.fname.toString();
    let lname = user?.lname.toString();
    let newName = fname.split(" ");
    let newLname = lname.split(" ");

    let temp_fname = "";
    for (let i = 0; i < newName.length; i++) {
      newName[i] = newName[i].toLowerCase();
      temp_fname = temp_fname + newName[i];
    }

    let temp_lname = "";
    for (let i = 0; i < newLname.length; i++) {
      newLname[i] = newLname[i].toLowerCase();
      temp_lname = temp_lname + newLname[i];
    }

    let user_fullname = temp_fname + temp_lname;

    let kyc_fullname = "";
    if (
      (userKycInfo?.onfido_doc_data?.first_name,
      userKycInfo?.onfido_doc_data?.last_name)
    ) {
      let kyc_fname = userKycInfo?.onfido_doc_data?.first_name;
      let kyc_lname = userKycInfo?.onfido_doc_data?.last_name;

      let kycName = kyc_fname.split(" ");
      let kycLname = kyc_lname.split(" ");

      let temp_fname1 = "";
      for (let i = 0; i < newName.length; i++) {
        if (kycName[i]) {
          kycName[i] = kycName[i].toLowerCase();
          temp_fname1 = temp_fname1 + kycName[i];
        }
      }

      let temp_lname1 = "";
      for (let i = 0; i < newLname.length; i++) {
        if (kycLname[i]) {
          kycLname[i] = kycLname[i].toLowerCase();
          temp_lname1 = temp_lname1 + kycLname[i];
        }
      }

      kyc_fullname = temp_fname1 + temp_lname1;

      //console.log(kyc_fullname);
    }

    if (user_fullname === kyc_fullname) {
      // if (true) {
      return <img src={tickNew} alt="" />;
    } else {
      // return "NOT MATCHED";
      if (user?.onfido_kyc_success == true) {
        return (
          <ul className="infodorpdown scrorebardrop">
            <li>
              <button className="iconinfo">
                <img src={crossNew} alt="" />
              </button>{" "}
            </li>
            <ul className="tooltip">
              {/* <li>1. Irregular Income</li> */}
              <li>{_t("Name does not match with digital ID")}</li>
            </ul>
          </ul>
        );
      } else {
        if (user?.onfido_kyc_success == false && userKycInfo?.onfido_doc)
          return verificationInProgress();
        else return verificationNotDone();
      }
    }
  };

  const checkNationality = () => {
    var kyc_nationality = "";
    if (userKycInfo?.onfido_doc_data?.nationality)
      kyc_nationality =
        userKycInfo?.onfido_doc_data?.nationality?.toLowerCase();
    let country_code = getAlpha3Code(user?.nationality?.toLowerCase());

    if (
      kyc_nationality &&
      (user?.nationality.toLowerCase() == kyc_nationality ||
        country_code.toLowerCase() == kyc_nationality)
    ) {
      // return kyc_nationality;
      return <img src={tickNew} alt="" />;
    } else {
      if (user?.onfido_kyc_success == true) {
        return (
          <ul className="infodorpdown scrorebardrop">
            <li>
              <button className="iconinfo">
                <img src={crossNew} alt="" />
              </button>{" "}
            </li>
            <ul className="tooltip">
              {/* <li>1. Irregular Income</li> */}
              <li>{_t("Nationality does not match with digital ID")}</li>
            </ul>
          </ul>
        );
      } else {
        if (user?.onfido_kyc_success == false && userKycInfo?.onfido_doc)
          return verificationInProgress();
        else return verificationNotDone();
      }
    }
  };

  const checkPassportKYC = () => {
    let kyc_type = userKycInfo?.onfido_doc?.document_front?.type;
    let document_number = userKycInfo?.onfido_doc_data?.document_number;
    if (kyc_type == "passport") {
      if (document_number == user?.passport_id)
        return <img src={tickNew} alt="" />;
      else {
        if (user?.onfido_kyc_success == true) {
          return (
            <ul className="infodorpdown scrorebardrop">
              <li>
                <button className="iconinfo">
                  <img src={crossNew} alt="" />
                </button>{" "}
              </li>
              <ul className="tooltip">
                {/* <li>1. Irregular Income</li> */}
                <li>{_t("Passport ID does not match with digital ID")}</li>
              </ul>
            </ul>
          );
        } else {
          if (user?.onfido_kyc_success == false && userKycInfo?.onfido_doc)
            return verificationInProgress();
          else return verificationNotDone();
        }
      }
    }

    return "";
  };
  const checkDNIKyc = () => {
    let kyc_type = userKycInfo?.onfido_doc?.document_front?.type;
    let document_number = userKycInfo?.onfido_doc_data?.personal_number;
    if (kyc_type != "passport") {
      if (document_number == user?.dni_nie) return <img src={tickNew} alt="" />;
      else {
        if (user?.onfido_kyc_success == true) {
          return (
            <ul className="infodorpdown scrorebardrop">
              <li>
                <button className="iconinfo">
                  <img src={crossNew} alt="" />
                </button>{" "}
              </li>
              <ul className="tooltip">
                {/* <li>1. Irregular Income</li> */}
                <li>{_t("Dni/Nie ID does not match with digital ID")}</li>
              </ul>
            </ul>
          );
        } else {
          if (user?.onfido_kyc_success == false && userKycInfo?.onfido_doc)
            return verificationInProgress();
          else return verificationNotDone();
        }
      }
    }

    return "";
  };

  const getEmploymentStatus = () => {
    if (user?.employment_status == "employed") {
      return "Employed";
    }
    if (user?.employment_status == "self_employed") {
      return "Self-Employed";
    }
    if (user?.employment_status == "student") {
      return "Student";
    }
    if (user?.employment_status == "unemployed") {
      return "Unemployed";
    }
  };

  const handleViewDocument = async () => {
    setShowSpinner(true);
    let userid = user?._id;
    getTenantKycDocs(userid)
      .then((res) => {
        console.log(res);
        const nodeJSBuffer = res.data.images.data;
        const uint8Array = new Uint8Array(nodeJSBuffer);

        // Convert Uint8Array to ArrayBuffer
        const arrayBuffer = uint8Array.buffer;

        // Create a Blob
        const blob = new Blob([arrayBuffer], {
          type: "application/octet-stream",
        });

        // Create a download link
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = "documents.zip";

        // Append the link to the body and click it to trigger the download
        document.body.appendChild(a);
        a.click();

        // Clean up
        document.body.removeChild(a);
        URL.revokeObjectURL(a.href);
        setShowSpinner(false);
      })
      .catch((err) => {
        setShowSpinner(false);
        console.log(err);
      });
  };

  const checkAllMatches = async () => {
    //console.log("CHECKALLMATCHES CALLED!")
    let fname = user?.fname.toString();
    let lname = user?.lname.toString();
    let newName = fname.split(" ");
    let newLname = lname.split(" ");

    let temp_fname = "";
    for (let i = 0; i < newName.length; i++) {
      newName[i] = newName[i].toLowerCase();
      temp_fname = temp_fname + newName[i];
    }

    let temp_lname = "";
    for (let i = 0; i < newLname.length; i++) {
      newLname[i] = newLname[i].toLowerCase();
      temp_lname = temp_lname + newLname[i];
    }

    let user_fullname = temp_fname + temp_lname;

    let kyc_fullname = "";
    if (
      (userKycInfo?.onfido_doc_data?.first_name,
      userKycInfo?.onfido_doc_data?.last_name)
    ) {
      let kyc_fname = userKycInfo?.onfido_doc_data?.first_name;
      let kyc_lname = userKycInfo?.onfido_doc_data?.last_name;

      let kycName = kyc_fname.split(" ");
      let kycLname = kyc_lname.split(" ");

      let temp_fname1 = "";
      for (let i = 0; i < newName.length; i++) {
        if (kycName[i]) {
          kycName[i] = kycName[i].toLowerCase();
          temp_fname1 = temp_fname1 + kycName[i];
        }
      }

      let temp_lname1 = "";
      for (let i = 0; i < newLname.length; i++) {
        if (kycLname[i]) {
          kycLname[i] = kycLname[i].toLowerCase();
          temp_lname1 = temp_lname1 + kycLname[i];
        }
      }

      kyc_fullname = temp_fname1 + temp_lname1;
    }
    // if (user_fullname !== kyc_fullname) {
    //   // if (true) {
    //   // console.log("failed in name")
    //   setIdent_all(false);
    //   return false;
    // }

    if (user?.onfido_kyc_success == false) {
      // if (true) {
      // console.log("failed in name")
      setIdent_all(false);
      return false;
    }

    var kyc_nationality = "";
    if (userKycInfo?.onfido_doc_data?.nationality)
      kyc_nationality = userKycInfo?.onfido_doc_data?.nationality.toLowerCase();
    let country_code = getAlpha3Code(user?.nationality.toLowerCase());

    if (
      kyc_nationality &&
      user?.nationality.toLowerCase() != kyc_nationality &&
      country_code.toLowerCase() != kyc_nationality
    ) {
      // console.log("failed in nationality")
      // setIdent_all(false);
      // return false;
    }

    let kyc_type = userKycInfo?.onfido_doc?.document_front?.type;
    let document_number = userKycInfo?.onfido_doc_data?.document_number;
    let personal_number = userKycInfo?.onfido_doc_data?.personal_number;
    if (kyc_type == "passport") {
      if (
        haveValue(user?.passport_id) &&
        document_number != user?.passport_id
      ) {
        // console.log("failed in passport")
        // setIdent_all(false);
        // return false;
      }
    } else {
      if (haveValue(user?.dni_nie) && personal_number != user?.dni_nie) {
        // console.log("failed in dnie")
        // setIdent_all(false);
        // return false;
      }
    }
    // console.log("failed no where")
    setIdent_all(true);
    return true;
  };

  const handleFileUpload = async () => {
    try {
      setUploadLoading(true);
      await uploadFileData(fileData, user?._id);

      setFileData(null);
      console.log("THINK COMPLETED");
      setFileType("");
      // setTimeout(async () => {
      await fetchFileData();
      handleClose();
      setUploadLoading(false);
      // }, 10000);
    } catch (err) {
      setUploadLoading(false);
      console.log(err);
    }
  };

  const handleFileChange = (e) => {
    const files = e.target.files;

    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append("tenantDocs", files[i]);
    }
    formData.append("fileType", fileType);

    setFileData(formData);
  };

  const handleSelectChange = (e) => {
    setFileType(e.target.value);
  };
  const fetchFileData = async () => {
    let userid = user?._id;
    const res = await fetchTenantDocs(userid);
    console.log(res);
    setTenantDocsData(res.data.success_res.tenantDocs);
  };

  const handleDeleteDoc = async (doc_id) => {
    try {
      const res = await deleteTenantDoc(doc_id);
      await fetchFileData();
      setDeleteId(null);
      setDeleteDocumentModal(false);
      if (res != false) toast.success(_t("Document Deleted Successfully"));
    } catch (err) {
      toast.error(_t("Unable To Delete Document"));
    }
  };

  const handleHideDocument = () => {
    setDeleteDocumentModal(false);
    setDeleteId(null);
  };

  const handleDownloadDoc = async (url, filename) => {
    // const pdfUrl = url;
    // const link = document.createElement("a");
    // link.href = pdfUrl;
    // link.download = filename; // specify the filename
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);

    fetch(url).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);

        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = filename;
        alink.click();
      });
    });
  };
  const handlePreviewDocument = (url) => {
    window.open(url, "blank");
  };

  const isPropertyLinked = (landlordid) => {
    for (const profileShared of fullProfileShared) {
      if (
        profileShared.landlordid === landlordid &&
        haveValue(profileShared?.propertyid) &&
        profileShared?.propertyid.length > 0
      ) {
        console.log(profileShared?.landlord_fname, profileShared?.propertyid);
        return profileShared;
      }
    }
    //console.log("profile not shared");
    return null;
  };

  useEffect(() => {
    countPropertyLinked();
  }, [landlords]);

  const countPropertyLinked = () => {
    var count = 0;
    landlords?.map((landlord, index) => {
      let property_linked = isPropertyLinked(landlord?._id);
      console.log(property_linked?.propertyid.length, "CHECK THE COUNT HERE");
      count += haveValue(property_linked?.propertyid.length)
        ? property_linked?.propertyid.length
        : 0;
    });
    setCountProp(count);
    console.log(count);
  };

  console.log(fullProfileShared, "fullProfileSharedfullProfileShared");
  const checkIeDetails = () => {
    var check = true;

    if (!haveValue(user?.ie_details_id)) {
      check = false;
      return check;
    }
    // if (user?.fname?.toLowerCase().replace(/\u00A0/g, ' ') != user?.ie_details_id?.fname?.toLowerCase().replace(/\u00A0/g, ' ')) {
    //   check = false;
    // }
    // if (user?.lname?.toLowerCase().replace(/\u00A0/g, ' ') != user?.ie_details_id?.lname?.toLowerCase().replace(/\u00A0/g, ' ')) {
    //   check = false
    // }
    if (user?.ie_student_id != user?.ie_details_id?.student_id) {
      check = false;
    }
    // if (user?.current_course.replace(/\u00A0/g, ' ') != user?.ie_details_id?.course_title.replace(/\u00A0/g, ' ')) {
    //   check = false
    // }
    return check;
  };
  return (
    <>
      <Accordion
        className="primaryaccordion"
        defaultActiveKey={currentActiveAccordion.toString()}
        onSelect={handleAccordionSelect}
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header className="primaryaccordion-header">
            <div className="d-flex justify-content-between w-100 align-items-center">
              <span className="maintitle"> {_t("Score and Details")} </span>
              {currentActiveAccordion.toString() != "0" && (
                <span
                  className={`accordion_score ${
                    haveValue(user?.gurantor_id) &&
                    haveValue(user?.gurantor_id?.paymentScores)
                      ? haveValue(
                          user?.gurantor_id?.paymentScores?.creditScore?.value
                        )
                        ? ""
                        : "no-score-heading"
                      : haveValue(user?.paymentScores?.creditScore?.value)
                      ? ""
                      : "no-score-heading"
                  }`}
                >
                  {haveValue(user?.gurantor_id) &&
                  haveValue(user?.gurantor_id?.paymentScores)
                    ? haveValue(
                        user?.gurantor_id?.paymentScores?.creditScore?.value
                      )
                      ? user?.gurantor_id?.paymentScores?.creditScore?.value
                      : _t("Not Scored")
                    : haveValue(user?.paymentScores?.creditScore?.value)
                    ? checkIeDetails() && user?.is_university
                      ? ieScore
                      : +user?.paymentScores?.creditScore?.value
                    : checkIeDetails() && user?.is_university
                    ? ieScore
                    : _t("Not Scored")}
                </span>
              )}
            </div>
          </Accordion.Header>
          <Accordion.Body className="primaryaccordion-body">
            <div className="tabs-box-pattern">
              <div id="main-content">
                <div id="page-container" className="tab-content">
                  <div id="home" className="tab-pane active padding-full-zero">
                    <div className="tabs-item d-block">
                      {/* START  */}

                      <div className="userscrollprofile-topbar">
                        {
                          <Row className="mx-0">
                            {haveValue(user?.gurantor_id) &&
                            haveValue(user?.gurantor_id?.paymentScores)
                              ? user?.gurantor_id?.paymentScores && (
                                  <Col xl={6} className="mb-3">
                                    <div className="border-pd">
                                      <h4>{_t("Score")} </h4>
                                      <div className="process-bar-wappers process2 ">
                                        {user?.gurantor_id?.paymentScores && (
                                          <>
                                            <div className="gradient-progress-block">
                                              <svg style={{ height: 0 }}>
                                                <defs>
                                                  <linearGradient
                                                    id="progressGradient"
                                                    gradientTransform="rotate(90)"
                                                  >
                                                    <stop
                                                      offset="0%"
                                                      stopColor={"red"}
                                                    />
                                                    <stop
                                                      offset="50%"
                                                      stopColor={"yellow"}
                                                    />
                                                    <stop
                                                      offset="100%"
                                                      stopColor={"green"}
                                                    />
                                                  </linearGradient>
                                                </defs>
                                              </svg>

                                              <CircularProgressbar
                                                width="75%"
                                                maxValue="1000"
                                                value={
                                                  user?.gurantor_id
                                                    ?.paymentScores.creditScore
                                                    .value
                                                }
                                                // value={800}
                                                text={`${user?.gurantor_id?.paymentScores.creditScore.value}`}
                                                circleRatio={0.5}
                                                strokeWidth={6}
                                                styles={buildStyles({
                                                  textColor: `${progressColor}`,
                                                  pathColor: `${progressColor} `,
                                                  rotation: 0.75,
                                                  strokeLinecap: "round",
                                                })}
                                              />
                                            </div>
                                            <div className="name-box">
                                              <h3 className="score-name">
                                                {
                                                  user?.gurantor_id?.kyc_details
                                                    ?.userDataByProvider[0]
                                                    ?.identity?.name
                                                }
                                                {"*"}
                                              </h3>
                                              <ul className="score-textgray">
                                                <span>
                                                  *
                                                  {_t(
                                                    "Bank Account Used For Scoring"
                                                  )}{" "}
                                                </span>
                                              </ul>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                )
                              : (user?.paymentScores ||
                                  (checkIeDetails() &&
                                    user?.is_university)) && (
                                  <Col xl={6} className="mb-3">
                                    <div className="border-pd">
                                      <h4>{_t("Score")}</h4>
                                      <div className="process-bar-wappers process2 ">
                                        {(user?.paymentScores ||
                                          (checkIeDetails() &&
                                            user?.is_university)) && (
                                          <>
                                            <div className="gradient-progress-block">
                                              <svg style={{ height: 0 }}>
                                                <defs>
                                                  <linearGradient
                                                    id="progressGradient"
                                                    gradientTransform="rotate(90)"
                                                  >
                                                    <stop
                                                      offset="0%"
                                                      stopColor={"red"}
                                                    />
                                                    <stop
                                                      offset="50%"
                                                      stopColor={"yellow"}
                                                    />
                                                    <stop
                                                      offset="100%"
                                                      stopColor={"green"}
                                                    />
                                                  </linearGradient>
                                                </defs>
                                              </svg>

                                              <CircularProgressbar
                                                width="75%"
                                                maxValue="1000"
                                                value={
                                                  checkIeDetails() &&
                                                  user?.is_university
                                                    ? ieScore
                                                    : user?.paymentScores
                                                        .creditScore.value
                                                }
                                                // value={800}
                                                text={`${
                                                  checkIeDetails() &&
                                                  user?.is_university
                                                    ? ieScore
                                                    : user?.paymentScores
                                                        .creditScore.value
                                                } `}
                                                circleRatio={0.5}
                                                strokeWidth={6}
                                                styles={buildStyles({
                                                  textColor: `${progressColor} `,
                                                  pathColor: `${progressColor} `,
                                                  rotation: 0.75,
                                                  strokeLinecap: "round",
                                                })}
                                              />
                                            </div>
                                            <div className="name-box">
                                              <h3 className="score-name">
                                                {
                                                  user?.kyc_details
                                                    ?.userDataByProvider[0]
                                                    ?.identity?.name
                                                }
                                                {"*"}
                                              </h3>
                                              <ul className="score-textgray">
                                                <span>
                                                  *
                                                  {_t(
                                                    "Bank Account Used For Scoring"
                                                  )}{" "}
                                                </span>
                                              </ul>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                )}

                            <SummarySection
                              user={user}
                              ident_all={ident_all}
                              recordedSalary={recordedSalary}
                              haveGuarantor={haveGuarantor}
                              handleViewDocument={handleViewDocument}
                            />
                            <ProfileInfoSection
                              user={user}
                              userKycInfo={userKycInfo}
                              setShowPhoneVerificationdModal={(e) => {
                                console.log("Only Valid For Tenant Profile!");
                              }}
                              handleRemoveGuarantor={(e) => {
                                console.log("Only Valid For Tenant Profile!");
                              }}
                              handleGurantorModal={(e) => {
                                console.log("Only Valid For Tenant Profile!");
                              }}
                              showActionPrompts={false}
                            />
                            <ProfessionalAcademicSection
                              user={user}
                              recordedSalary={recordedSalary}
                              showActionPrompts={false}
                            />
                          </Row>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header className="primaryaccordion-header">
            <div className="d-flex justify-content-between w-100 align-items-center">
              <span className="maintitle">
                {_t("Documents")}{" "}
                <label className="">
                  <ul className="infodorpdown summarydropdum position-relative">
                    <li>
                      <button className="iconinfo guarantor_tooltip">
                        <MdInfoOutline />
                      </button>{" "}
                    </li>
                    <ul className="tooltip">
                      <li>{_t("Pdf files only")}</li>
                      <li>{_t("Each file size 10 MB max")}</li>
                      <li>{_t("10 documents max")}</li>
                    </ul>
                  </ul>
                </label>
              </span>
              {currentActiveAccordion.toString() != "1" && (
                <span className="submain">
                  {Array.isArray(tenantDocsData)
                    ? `${tenantDocsData.length} ${_t("Documents")} `
                    : "  "}
                </span>
              )}
            </div>
          </Accordion.Header>
          <Accordion.Body className="primaryaccordion-body">
            {(adminUser?.role == constants.SUPER_ADMIN ||
              adminUser?.role == constants.ADMIN) && (
              <>
                {" "}
                <h4 className="accordion-title">
                  {_t("Select Document Type")}
                </h4>
                <Stack direction="horizontal" gap={2}>
                  <Form.Select
                    size="lg"
                    onChange={(e) => {
                      handleSelectChange(e);
                    }}
                    value={fileType}
                  >
                    <option value="" hidden>
                      {_t("Select")}
                    </option>
                    <option value="Employment Contract">
                      {_t("Employment Contract (Employed)")}
                    </option>
                    <option value="Payslips">
                      {_t("Payslips - Last Three (Employed)")}
                    </option>
                    <option value="Income Tax Return">
                      {_t("Income Tax Return (Self-Employed)")}
                    </option>
                    <option value="Corporate Tax Return">
                      {_t("Corporate Tax Return (Company)")}
                    </option>
                    <option value="Tax Agency Payments">
                      {_t("Certificate of Payments to Tax Agency (Company)")}
                    </option>
                    <option value="Social Security Payments">
                      {_t(
                        "Certificate of Payments to Social Security (Company)"
                      )}
                    </option>
                    <option value="Student registration contract">
                      {_t("Student registration contract")}
                    </option>
                    <option value="Last annual Tax Report (Model 100)">
                      {_t("Last annual Tax Report (Model 100)")}
                    </option>
                    <option value="Powers of Attorney">
                      {_t("Powers of Attorney (Company)")}
                    </option>
                    <option value="University Acceptance Letter">
                      {_t("University Acceptance Letter (Student)")}
                    </option>
                    <option value="Other Documents">
                      {_t("Other Documents")}
                    </option>
                  </Form.Select>
                  <Button
                    onClick={() => {
                      handleShow();
                    }}
                    disabled={haveValue(fileType) ? false : true}
                  >
                    {_t("Upload")}
                  </Button>
                </Stack>
                <Modal
                  className="modal-uploadpdf"
                  show={show}
                  onHide={handleClose}
                >
                  <Modal.Header closeButton>
                    {_t("Select Document")}
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Group controlId="formFile" className="formcontrolbar">
                      <input
                        type="file"
                        onChange={(e) => {
                          handleFileChange(e);
                        }}
                        multiple
                        accept="application/pdf"
                      />
                      <Button
                        className="btn-green"
                        onClick={handleFileUpload}
                        disabled={
                          haveValue(fileData) && !uploadLoading ? false : true
                        }
                      >
                        {uploadLoading ? _t("Please Wait") : _t("Upload")}
                      </Button>
                    </Form.Group>
                  </Modal.Body>
                </Modal>
              </>
            )}
            <Row className="card-listing">
              {tenantDocsData?.map((eachData, index) => (
                <Col md={3} key={eachData?._id}>
                  <Card>
                    <div className="videoframe">
                      <iframe
                        src={`${BASE_URL}/uploads/${eachData?.file_name} `}
                        scrolling="no"
                      />
                    </div>
                    <Card.Body>
                      <div className="listing-head">
                        <div className="head-block">
                          <div className="leftbartext">
                            <h5>{eachData?.type}</h5>
                            <p>
                              {eachData?.file_name
                                .split("-")
                                .slice(1)
                                .join("-")}
                            </p>
                          </div>
                          <VscEye
                            onClick={(e) =>
                              handlePreviewDocument(
                                `${BASE_URL}/uploads/${eachData?.file_name} `
                              )
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>
                      <div className="listing-foot">
                        <span>
                          {moment(eachData?.createdAt).format("DD/MM/YYYY")}
                        </span>
                        <div className="iconssgroups">
                          <span
                            className="del-icon"
                            onClick={(e) =>
                              handleDownloadDoc(
                                `${BASE_URL}/uploads/${eachData?.file_name} `,
                                eachData?.file_name
                              )
                            }
                          >
                            <RiDownloadLine style={{ cursor: "pointer" }} />
                          </span>
                          {adminUser?.role == constants.SUPER_ADMIN && (
                            <span
                              className="del-icon deleteicon"
                              onClick={(e) => {
                                setDeleteId(eachData?._id);
                                setDeleteDocumentModal(true);
                              }}
                            >
                              <RiDeleteBin6Line style={{ cursor: "pointer" }} />
                            </span>
                          )}
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header className="primaryaccordion-header">
            <div className="d-flex justify-content-between w-100 align-items-center">
              <span className="maintitle">{_t("Profile Shared")} </span>
              {currentActiveAccordion.toString() != "3" && (
                <span className="submain">
                  {haveValue(fullProfileShared)
                    ? `${fullProfileShared.length} ${_t("Landlords")} `
                    : ""}
                </span>
              )}
            </div>
          </Accordion.Header>
          <Accordion.Body className="primaryaccordion-body">
            <Row className="card-listing mt-0">
              <ol>
                {fullProfileShared &&
                  fullProfileShared?.map((profileShared, index) => {
                    return (
                      <li>
                        {profileShared?.landlord_fname}{" "}
                        {profileShared?.landlord_lname}
                      </li>
                    );
                  })}
              </ol>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header className="primaryaccordion-header">
            <div className="d-flex justify-content-between w-100 align-items-center">
              <span className="maintitle">{_t("Linked Properties")} </span>
              {currentActiveAccordion.toString() != "2" && (
                <span className="submain">
                  {haveValue(countProp)
                    ? `${countProp} ${_t("Properties")} `
                    : ""}
                </span>
              )}
            </div>
          </Accordion.Header>
          <Accordion.Body className="primaryaccordion-body">
            <Row className="card-listing mt-0">
              {landlords &&
                landlords?.map((landlord, index) => {
                  let property_linked = isPropertyLinked(landlord?._id);
                })}
              {landlords &&
                landlords?.map((landlord, index) => {
                  let property_linked = isPropertyLinked(landlord?._id);
                  return (
                    // isProfileShared(landlord?._id) ?
                    haveValue(property_linked) ? (
                      <>
                        <div
                          className="Notifications-wapper opacity-wapper px-0 tt"
                          key={landlord?._id}
                        >
                          <div>
                            <h4>
                              {landlord?.fname_companyName} {landlord?.lname}
                            </h4>
                            {haveValue(property_linked) && (
                              <p className="d-flex gap-2">
                                <a href="#">
                                  <img
                                    className="location-img"
                                    src={locationicon}
                                    alt=""
                                  />
                                </a>
                                {property_linked?.propertyid
                                  ?.map(
                                    (props) =>
                                      `${props?.street} - ${props?.street_number}, ${props.floor_number}-${props.flat_number}`
                                  )
                                  .join(" / ")}
                              </p>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )
                  );
                })}
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Modal
        show={deleteDocumentModal}
        className="metermodal"
        centered
        onHide={handleHideDocument}
      >
        <Modal.Body>
          {/* <h4>{_t("Your House Measures")} {formData?.area} {_t("Meters, Is That Correct")}?</h4> */}
          <h4>
            {_t(
              "Are you sure you want to delete this document? Please note that this action cannot be undone."
            )}
          </h4>
          <div className="auth-form">
            <div className="btngroup row100 gridbar">
              <button
                className="btn btnoutline"
                type="button"
                onClick={(e) => {
                  handleHideDocument();
                }}
              >
                {_t("No")}
              </button>
              <button
                className="btn btngreen"
                onClick={(e) => handleDeleteDoc(deleteId)}
              >
                {_t("Yes")}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UserScoreProfile;

import axios from "axios";
import { BASE_URL } from "../../config";
// import toast, { ToastIcon } from "react-hot-toast";
import { toast } from "react-toastify";

export const getAllTenants = async (
  currentPage = 1,
  searchText = "",
  type = "all",
  sortBy = "Latest",
  digital_verification = "",
  bank_verified = "",
  have_guarantor = "",
  guarantor_scored = "",
  linked_property = "",
  sort_type = "",
  joined_from = ""
) => {
  try {
    var sort_by = sortBy;
    var sort_type = sort_type;
    if (sortBy == "Latest" || sortBy == null) {
      sort_by = "created_at";
      sort_type = "desc";
    } else if (sortBy == "Oldest") {
      sort_by = "created_at";
      sort_type = "asc";
    } else if (sortBy == "Name [A-Z]") {
      sort_by = "fname";
      sort_type = "asc";
    } else if (sortBy == "Name [Z-A]") {
      sort_by = "fname";
      sort_type = "desc";
    }

    var url = `${BASE_URL}/admin/tenants`;
    if (type != "all")
      url += `?page=${currentPage}&limit=10&type=${type}&searchText=${searchText}&sortBy=${sort_by}&sortType=${sort_type}&digital_verification=${digital_verification}&bank_verified=${bank_verified}&have_guarantor=${have_guarantor}&guarantor_scored=${guarantor_scored}&linked_property=${linked_property}&joined_from=${joined_from}`;
    else url += `?type=${type}`;
    const axiosRes = await axios({
      method: "GET",
      headers: { "x-access-token": localStorage.getItem("token") },
      url: url,
    });
    console.log("getAllTenants [SUCCESS]", axiosRes.data);
    return axiosRes.data;
  } catch (err) {
    console.log("getAllTenants [ERROR] =>", err);
  }
};

export const getAllLandlords = async (
  currentPage = 1,
  search = "",
  sortBy = "Latest",
  role = "",
  isLandlordInComp = "",
  sortType = "asc"
) => {
  try {
    var sort_by = sortBy;
    var sort_type = sortType;
    if (sortBy == "Latest" || sortBy == null) {
      sort_by = "createdAt";
      sort_type = "desc";
    } else if (sortBy == "Oldest") {
      sort_by = "createdAt";
      sort_type = "asc";
    } else if (sortBy == "Name [A-Z]") {
      sort_by = "fname_companyName";
      sort_type = "asc";
    } else if (sortBy == "Name [Z-A]") {
      sort_by = "fname_companyName";
      sort_type = "desc";
    }

    const axiosRes = await axios({
      method: "GET",
      headers: { "x-access-token": localStorage.getItem("token") },
      url: `${BASE_URL}/admin/landlords?page=${currentPage}&limit=10&searchText=${search}&sortBy=${sort_by}&sortType=${sort_type}&role=${role}&isLandlordInComp=${isLandlordInComp}`,
    });
    console.log("getAllLandlords [SUCCESS]", axiosRes.data);
    return axiosRes.data;
  } catch (err) {
    console.log("getAllLandlords [ERROR] =>", err);
  }
};

export const getAllProperties = async (
  currentPage = 1,
  searchText = "",
  type = "all",
  sortBy = "Latest"
) => {
  try {
    var sort_by, sort_type;
    if (sortBy == "Latest") {
      sort_by = "created_at";
      sort_type = "desc";
    } else if (sortBy == "Oldest") {
      sort_by = "created_at";
      sort_type = "asc";
    }
    var url = `${BASE_URL}/admin/properties`;
    if (type != "all")
      url += `?page=${currentPage}&limit=10&type=${type}&searchText=${searchText}&sortBy=${sort_by}&sortType=${sort_type}`;
    else url += `?type=${type}`;
    const axiosRes = await axios({
      method: "GET",
      headers: { "x-access-token": localStorage.getItem("token") },
      url: url,
    });
    console.log("getAllProperties [SUCCESS]", axiosRes.data);
    return axiosRes.data;
  } catch (err) {
    console.log("getAllProperties [ERROR] =>", err);
  }
};

export const getAllPropertyDetails = async (
  currentPage = 1,
  searchText = "",
  type = "all",
  sortBy = "Latest"
) => {
  try {
    var sort_by, sort_type;
    if (sortBy == "Latest") {
      sort_by = "created_at";
      sort_type = "desc";
    } else if (sortBy == "Oldest") {
      sort_by = "created_at";
      sort_type = "asc";
    }
    var url = `${BASE_URL}/admin/properties-details`;
    if (type != "all")
      url += `?page=${currentPage}&limit=10&type=${type}&searchText=${searchText}&sortBy=${sort_by}&sortType=${sort_type}`;
    else url += `?type=${type}`;
    const axiosRes = await axios({
      method: "GET",
      headers: { "x-access-token": localStorage.getItem("token") },
      url: url,
    });
    console.log("getAllPropertiesDetails [SUCCESS]", axiosRes.data);
    return axiosRes.data;
  } catch (err) {
    console.log("getAllProperties [ERROR] =>", err);
  }
};

export const getAllOffers = async () => {
  try {
    const axiosRes = await axios({
      method: "GET",
      headers: { "x-access-token": localStorage.getItem("token") },
      url: `${BASE_URL}/admin/offers`,
    });
    console.log("getAllOffers [SUCCESS]", axiosRes.data);
    return axiosRes.data;
  } catch (err) {
    console.log("getAllOffers [ERROR] =>", err);
  }
};

//single functions
export const getLandlordProperties = async (landlordid) => {
  try {
    const axiosRes = await axios({
      method: "GET",
      headers: { "x-access-token": localStorage.getItem("token") },
      url: `${BASE_URL}/properties/${landlordid}`,
    });
    console.log("getLandlordProperties [SUCCESS]", axiosRes.data);
    return axiosRes.data;
  } catch (err) {
    console.log("getLandlordProperties [ERROR] =>", err);
  }
};

export const getLandlordPropertyDetails = async (landlordid) => {
  try {
    const axiosRes = await axios({
      method: "GET",
      headers: { "x-access-token": localStorage.getItem("token") },
      url: `${BASE_URL}/properties/property-details/${landlordid}`,
    });
    console.log("getLandlordProperties [SUCCESS]", axiosRes.data);
    return axiosRes.data;
  } catch (err) {
    console.log("getLandlordProperties [ERROR] =>", err);
  }
};

export const getSingleUser = async (userid, type) => {
  try {
    const axiosRes = await axios({
      method: "GET",
      headers: { "x-access-token": localStorage.getItem("token") },
      url: `${BASE_URL}/admin/users/${userid}?type=${type}`,
    });
    console.log("getSingleUser [SUCCESS]", axiosRes.data);
    return axiosRes.data;
  } catch (err) {
    console.log("getSingleUser [ERROR] =>", err);
  }
};

export const createOffer = async (formvalue) => {
  try {
    const axiosRes = await axios({
      method: "POST",
      headers: { "x-access-token": localStorage.getItem("token") },
      url: `${BASE_URL}/offers/`,
      data: formvalue,
    });
    console.log("createOffer [SUCCESS]", axiosRes.data);
    return axiosRes.data;
  } catch (err) {
    console.log("createOffer [ERROR] =>", err);
  }
};

export const editOffer = async (formvalue, offerid) => {
  try {
    const axiosRes = await axios({
      method: "POST",
      headers: { "x-access-token": localStorage.getItem("token") },
      url: `${BASE_URL}/offers/single/${offerid}`,
      data: formvalue,
    });
    console.log("editOffer [SUCCESS]", axiosRes.data);
    return axiosRes.data;
  } catch (err) {
    console.log("editOffer [ERROR] =>", err);
  }
};

export const deleteOffer = async (offerid) => {
  try {
    const axiosRes = await axios({
      method: "DELETE",
      headers: { "x-access-token": localStorage.getItem("token") },
      url: `${BASE_URL}/offers/single/${offerid}`,
    });
    console.log("deleteOffer [SUCCESS]", axiosRes.data);
    return axiosRes.data;
  } catch (err) {
    console.log("deleteOffer [ERROR] =>", err);
  }
};

export const getFeedbackDetails = async (tenantId, propId) => {
  try {
    const axiosRes = await axios({
      method: "GET",
      headers: { "x-access-token": localStorage.getItem("token") },
      url: `${BASE_URL}/landlords/get-feedback/${propId}/${tenantId}`,
    });
    return axiosRes.data;
  } catch (err) {
    console.log("getFeedbackDetails [ERROR] =>", err);
  }
};

export const getTenantKycDocs = async (tenantid) => {
  try {
    const docs = await axios.get(
      `${BASE_URL}/tenants/get-document/${tenantid}`,
      {
        headers: {
          "Accept-Language": localStorage.getItem("i18nextLng"),
          responseType: "blob",
        },
      }
    );
    return docs;
  } catch (err) {
    console.log(err);
    toast.error("Error while fetching Tenant KYC Docs!");
  }
};

export const fetchTenantDocs = async (tenantId) => {
  try {
    const res = await axios.get(
      `${BASE_URL}/tenants/getUploadedDocuments?tenantId=${tenantId}`,
      {},
      {
        headers: {
          "Accept-Language": localStorage.getItem("i18nextLng"),
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const uploadFileData = async (filedata, tenantid) => {
  try {
    const res = await axios.post(
      `${BASE_URL}/tenants/admin/uploadDocument/${tenantid}`,
      filedata,
      {
        headers: {
          "Accept-Language": localStorage.getItem("i18nextLng"),
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return res;
  } catch (err) {
    toast.error("Error In Uploading File!");
  }
};

export const deleteTenantDoc = async (docId) => {
  try {
    const res = await axios.delete(
      `${BASE_URL}/tenants/deleteDoc?docId=${docId}`,
      { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
    );
    return res;
  } catch (err) {
    toast.error("Unable To Delete Document");
    console.log(err);
    return false;
  }
};
export const deleteLandlordDoc = async (docId) => {
  try {
    const res = await axios.delete(
      `${BASE_URL}/landlords/deleteDoc?docId=${docId}`,
      { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
    );
    return res;
  } catch (err) {
    toast.error("Unable To Delete Document");
    console.log(err);
    return false;
  }
};

export const loadSharedProfiles = async (tenantid) => {
  try {
    const res = await axios.get(
      `${BASE_URL}/shared-profile/myshared-profiles/${tenantid}?profiletype=full`,
      { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
    );
    let serverResponse = res.data;
    return serverResponse;
  } catch (err) {
    toast.error("Error while fetching shared profiles");
  }
};

export const landlordLoadSharedProfiles = async (landlordId) => {
  try {
    const res = await axios.get(
      `${BASE_URL}/shared-profile/landlord-received/${landlordId}?profiletype=full`,
      { headers: { "Accept-Language": localStorage.getItem("i18nextLng") } }
    );
    let serverResponse = res.data;
    return serverResponse;
  } catch (err) {
    toast.error("Error while fetching shared profiles");
  }
};

export const getWaitlist = async (
  searchVal,
  currentPage,
  sort_type = "desc"
) => {
  try {
    const res = await axios.get(
      `${BASE_URL}/admin/waitlist/data?page=${currentPage}&limit=10&searchText=${searchVal}&sortType=${sort_type}`,
      { headers: { "x-access-token": localStorage.getItem("token") } }
    );
    let serverResponse = res.data;
    return serverResponse;
  } catch (err) {
    toast.error("Error while fetching shared profiles");
  }
};

export const downloadDocuSignDoc = async (data) => {
  try {
    const res = await axios.post(`${BASE_URL}/admin/download/docuSign`, data, {
      headers: { "x-access-token": localStorage.getItem("token") },
    });
    let serverResponse = res.data;
    return serverResponse;
  } catch (err) {
    toast.error("Error while downloading document!");
  }
};

export const updateUserInfo = async (formData = {}, id, type) => {
  try {
    const data = {
      formData,
      id: id,
      type,
    };
    const result = await axios.patch(`${BASE_URL}/users/update/info`, data, {
      headers: { "Accept-Language": localStorage.getItem("i18nextLng") },
    });
    return result.data;
  } catch (error) {
    toast.error("Error while updating info!");
  }
};

export const loadTransactions = async (
  type,
  searchVal,
  currentPage,
  sort_type = "desc",
  sortBy = "Latest"
) => {
  try {
    var sort_by = sortBy;
    if (sortBy == "Latest" || sortBy == null) {
      sort_by = "created_at";
      sort_type = "desc";
    }
    const res = await axios.get(
      `${BASE_URL}/admin/transaction/list?type=${type}&page=${currentPage}&limit=10&searchText=${searchVal}&sortType=${sort_type}&sortBy=${sort_by}`,
      {
        headers: { "x-access-token": localStorage.getItem("token") },
      }
    );
    let serverResponse = res.data;
    return serverResponse;
  } catch (err) {
    console.log(err);
  }
};

export const createSubAdmin = async (user_data) => {
  try {
    const res = await axios.post(`${BASE_URL}/users/signup`, user_data, {
      headers: { "x-access-token": localStorage.getItem("token") },
    });
    let serverResponse = res.data;
    toast.success("Sub Admin Created");
    return serverResponse;
  } catch (err) {
    console.log(err);
    toast.error(err.response.data.error);
  }
};

export const getAdminList = async () => {
  try {
    const res = await axios.get(`${BASE_URL}/admin/getList`, {
      headers: { "x-access-token": localStorage.getItem("token") },
    });
    let serverResponse = res.data;
    return serverResponse;
  } catch (err) {
    console.log(err);
    toast.error(err.response.data.error);
  }
};
export const changeUserStatus = async (data) => {
  try {
    const res = await axios.post(`${BASE_URL}/users/change/status`, data, {
      headers: { "x-access-token": localStorage.getItem("token") },
    });
    let serverResponse = res.data;
    return serverResponse;
  } catch (err) {
    console.log(err);
    toast.error(err.response.data.error);
  }
};

export const adminLogin = async (data) => {
  try {
    const res = await axios.post(`${BASE_URL}/admin/user/login`, data, {
      headers: { "x-access-token": localStorage.getItem("token") },
    });
    let serverResponse = res.data;
    return serverResponse;
  } catch (err) {
    console.log(err);
    toast.error(err.response.data.error);
  }
};

export const updateBulkUserInfo = async (formData = {}, ids = [], type) => {
  try {
    const data = {
      formData,
      ids: ids,
      type,
    };
    const result = await axios.patch(
      `${BASE_URL}/users/bulk/update/info`,
      data,
      {
        headers: { "Accept-Language": localStorage.getItem("i18nextLng") },
      }
    );
  } catch (error) {
    toast.error("Error while updating info!");
  }
};

export const loadPotentialLandlords = async (
  type,
  currentPage,
  sort_type = "desc",
  sortBy = "Latest"
) => {
  try {
    var sort_by = sortBy;
    if (sortBy == "Latest" || sortBy == null) {
      sort_by = "created_at";
      sort_type = "desc";
    }
    const res = await axios.get(
      `${BASE_URL}/admin/transaction/potentials/landlords?type=${type}&page=${currentPage}&limit=10&sortType=${sort_type}&sortBy=${sort_by}`,
      {
        headers: { "x-access-token": localStorage.getItem("token") },
      }
    );
    let serverResponse = res.data;
    return serverResponse;
  } catch (err) {
    console.log(err);
  }
};
export const updateCompanyData = async (formData, landlordId) => {
  try {
    const res = await axios.post(
      `${BASE_URL}/admin/updateCompany/${landlordId}`,
      formData,
      {
        headers: {
          "Accept-Language": localStorage.getItem("i18nextLng"),
          "Content-Type": "multipart/form-data",
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );
    return res;
  } catch (err) {
    toast.error("Error In Uploading File!");
  }
};
export const uploadLandlordFiles = async (formData, landlordId) => {
  try {
    const res = await axios.post(
      `${BASE_URL}/admin/upload/landlord/docs/${landlordId}`,
      formData,
      {
        headers: {
          "Accept-Language": localStorage.getItem("i18nextLng"),
          "Content-Type": "multipart/form-data",
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );
    return res;
  } catch (err) {
    toast.error("Error In Uploading File!");
  }
};
export const fetchLandlordFiles = async (landlordId) => {
  try {
    const res = await axios.get(
      `${BASE_URL}/admin/fetch/landlord/docs/${landlordId}`,
      {
        headers: {
          "Accept-Language": localStorage.getItem("i18nextLng"),
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );
    return res;
  } catch (err) {}
};

export const fetchTranslations = async () => {
  try {
    const res = await axios.get(`${BASE_URL}/admin/getTranslations`, {
      headers: {
        "Accept-Language": localStorage.getItem("i18nextLng"),
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return res;
  } catch (err) {}
};

export const updateTranslations = async (data) => {
  try {
    const res = await axios.post(`${BASE_URL}/admin/updateTranslations`, data, {
      headers: {
        "Accept-Language": localStorage.getItem("i18nextLng"),
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return res;
  } catch (err) {
    toast.error("Error In Uploading File!");
  }
};

export const getTenantJoinedFromList = async () => {
  try {
    const docs = await axios.get(`${BASE_URL}/admin/tenants/joined-from-list`, {
      headers: {
        "Accept-Language": localStorage.getItem("i18nextLng"),
        "x-access-token": localStorage.getItem("token"),
      },
    });
    return docs;
  } catch (err) {
    console.log(err);
    toast.error("Error while fetching Tenant KYC Docs!");
  }
};

export const downloadTinkReport = async (tenant_id) => {
  try {
    const docs = await axios.get(
      `${BASE_URL}/admin/get/tink/report/${tenant_id}`,
      {
        headers: {
          "Accept-Language": localStorage.getItem("i18nextLng"),
          "x-access-token": localStorage.getItem("token"),
          responseType: "blob",
        },
      }
    );
    return docs;
  } catch (err) {
    console.log(err);
    toast.error("Error while fetching Tenant KYC Docs!");
  }
};

import React, { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import {
  RiDashboardLine,
  RiLogoutBoxLine,
  RiUserReceivedLine,
} from "react-icons/ri";
import { FiUsers, FiHome } from "react-icons/fi";
import { BiUser } from "react-icons/bi";
import { GrTransaction } from "react-icons/gr";
import { FaRegUser } from "react-icons/fa6";
import { SlUserFollow } from "react-icons/sl";
import { MdGTranslate } from "react-icons/md";

import { BsHouse, BsHouseDoor } from "react-icons/bs";
import { TbDiscount } from "react-icons/tb";
import "bootstrap/dist/css/bootstrap.min.css";
import Accordion from "react-bootstrap/Accordion";
import { Ucontext } from "../context/Ucontext";
import { useLocation } from "react-router-dom";
import { constants } from "../utils/constants";
const Sidebar = () => {
  const { authState, authDispatch, login, logout } = useContext(Ucontext);
  const { user: adminUser } = authState;

  //state
  const location = useLocation();
  const isPropertiesActive = ["/rentprop", "/unrentprop"].includes(
    location.pathname
  );

  const logoutAdmin = () => {
    logout();
    authDispatch({ type: "LOGOUT", payload: "logged out" });
  };
  console.log(adminUser, "CHECK USER HERE");
  return (
    <>
      <div className="sidebar-panel">
        <div className="sidebarhead">
          <Link className="logobar">
            <img src="/images/logo.svg" alt="logo" />
          </Link>
          <ul>
            <li>
              <NavLink
                onClick={() => document.body.classList.remove("main")}
                to="/"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <RiDashboardLine size={20} />
                Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={() => document.body.classList.remove("main")}
                to="/landlord"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <BsHouseDoor size={20} />
                Landlord/ Agent
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={() => document.body.classList.remove("main")}
                to={`/tenants`}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <FiUsers size={20} />
                Tenants
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={() => document.body.classList.remove("main")}
                to={`/waitlist`}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <BiUser size={20} />
                Waitlist
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={() => document.body.classList.remove("main")}
                to={`/transactions/rentalInfo`}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <FiHome size={20} />
                Tenant Rental Transaction
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={() => document.body.classList.remove("main")}
                to={`/transactions/assigned`}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <GrTransaction size={20} />
                Assigned Properties Transaction
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={() => document.body.classList.remove("main")}
                to={`/potential/landlords`}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <FaRegUser size={20} />
                Potential Landlords
              </NavLink>
            </li>

            <li>
              <Accordion
                defaultActiveKey={
                  location.pathname.includes("/rentprop") ||
                  location.pathname.includes("/unrentprop")
                    ? "1"
                    : "0"
                }
              >
                <Accordion.Item eventKey="1">
                  <Accordion.Header
                    className={isPropertiesActive ? "active" : ""}
                  >
                    <BsHouse size={20} /> Properties
                  </Accordion.Header>
                  <Accordion.Body>
                    <NavLink
                      onClick={() => document.body.classList.remove("main")}
                      to="/rentprop"
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      Rented Properties
                    </NavLink>
                    <NavLink
                      onClick={() => document.body.classList.remove("main")}
                      to="/unrentprop"
                      className={({ isActive }) => (isActive ? "active" : "")}
                    >
                      Unrented Properties
                    </NavLink>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </li>

            {adminUser?.role == constants.SUPER_ADMIN && (
              <li>
                <NavLink
                  onClick={() => document.body.classList.remove("main")}
                  to={`/add/admin`}
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  <SlUserFollow size={20} />
                  Sub Admin
                </NavLink>
              </li>
            )}

            {(adminUser?.role == constants.ADMIN ||
              adminUser?.role == constants.SUPER_ADMIN) && (
              <li>
                <NavLink
                  onClick={() => document.body.classList.remove("main")}
                  to={`/translation`}
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  <MdGTranslate size={20} />
                  Translation
                </NavLink>
              </li>
            )}

            <li>
              <Link onClick={logoutAdmin} to="/login">
                <RiLogoutBoxLine size={20} />
                Logout
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;

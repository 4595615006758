import React, { useState, useEffect, useContext } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { AiOutlineHistory } from "react-icons/ai";
import { ImBin } from "react-icons/im";
import { MdLocationPin } from "react-icons/md";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { Link, useLocation } from "react-router-dom";
import { GiSettingsKnobs } from "react-icons/gi";
import { HiMagnifyingGlass } from "react-icons/hi2";
import {
  InputGroup,
  Dropdown,
  Popover,
  OverlayTrigger,
  Form,
} from "react-bootstrap";
import { Ucontext } from "../context/Ucontext";
import RentedProp from "../components/properties/RentedProp";
import UnRentedProp from "../components/properties/UnRentedProp";
import Loading from "../components/loader/Loading";
import ReactPaginate from "react-paginate";
import { MdNavigateNext } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";
const RentedProperties = () => {
  const { adminDispatch, adminState, getAllProperties, getAllPropertyDetails } =
    useContext(Ucontext);

  const { totalProperties } = adminState;
  const [searchVal, setSearchVal] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortBy, setSortBy] = useState("Latest");

  const location = useLocation();
  console.log(location.pathname);

  const [rentedProperties, setRentedProperties] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadProperties(currentPage, searchVal, sortBy);
  }, [currentPage, searchVal, sortBy]);

  useEffect(() => {
    checkRented();
  }, [totalProperties]);

  const loadProperties = async (currentPage, searchVal, sortBy) => {
    setLoading(true);
    // let properties = await getAllProperties(currentPage, searchVal, 'rented', sortBy);
    let properties = await getAllPropertyDetails(
      currentPage,
      searchVal,
      "rented",
      sortBy
    );
    if (properties.status == "success") {
      adminDispatch({
        type: "PROPERTIES_LOADED",
        payload: properties?.properties,
      });
      setTotalPages(properties.totalPages);

      setLoading(false);
    }
    setLoading(false);
  };

  const checkRented = () => {
    let rentedProperties = [];
    let unRentedProperties = [];
    for (let property of totalProperties) {
      if (property?.is_awarded) {
        rentedProperties.push(property);
      } else {
        unRentedProperties.push(property);
      }
    }
    setRentedProperties(rentedProperties);
  };

  const handleSort = (sortType) => {
    console.log({ sortType });
    setSortBy(sortType);
    setcurrentPage(1);
  };

  const handleChange = (event) => {
    setSortBy(event.target.value);
    setcurrentPage(1);
  };

  const handleSearch = (e) => {
    setSearchVal(e.target.value);
    setcurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0) {
      setcurrentPage(newPage);
    }
  };

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    setcurrentPage(event.selected + 1);
  };

  return (
    <>
      {loading ? <Loading /> : null}
      <div className="rented-main">
        <div className="header_table d-flex justify-content-between align-items-center">
          <h1>Rented Properties </h1>
        </div>
        <div className="searchbar_table row me-1 mb-2">
          <div className="col-md-2 p-0">
            <InputGroup className="input_table_header">
              <InputGroup.Text id="basic-addon1">
                <HiMagnifyingGlass />
              </InputGroup.Text>
              <Form.Control
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon1"
                value={searchVal}
                onChange={handleSearch}
              />
            </InputGroup>
          </div>
          <div className="col-md-2 p-0">
            <Form.Select onChange={handleChange}>
              <option value="Latest">Latest</option>
              <option value="Oldest">Oldest</option>
            </Form.Select>{" "}
          </div>

          {/* <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={
                <Popover id="popover-basic">
                  <Popover.Body>
                    <div>
                      <p>Sort By</p>
                      <div className="popover_buttons">
                        <Dropdown onSelect={handleSort}>
                          <Dropdown.Toggle
                            style={{
                              width: "226px",
                              border: "1px solid #c8c8c8ad",
                              borderRadius: "12px",
                            }}
                            variant="light"
                            id="dropdown-basic"
                          >
                            {sortBy}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item eventKey="Latest">
                              Latest
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="Oldest">
                              Oldest
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </Popover.Body>
                </Popover>
              }
            >
              <button>
                <GiSettingsKnobs size={22} color="var(--dark)" />
              </button>
            </OverlayTrigger> */}
        </div>

        <RentedProp properties={rentedProperties ?? []} />
        <ReactPaginate
          nextLabel={<MdNavigateNext />}
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={totalPages}
          previousLabel={<GrFormPrevious />}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
          forcePage={currentPage - 1}
        />
        {/* <div className="">
              <button
                onClick={() => handlePageChange(+currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span>{`Page ${currentPage} of ${totalPages}`}</span>
              <button
                onClick={() => handlePageChange(+currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div> */}
      </div>
    </>
  );
};

export default RentedProperties;

import React, { useContext, useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { BsPencil } from "react-icons/bs";
import { ImBin } from "react-icons/im";
import { Form, Dropdown } from "react-bootstrap";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Modal from "react-bootstrap/Modal";
import { NavLink, useNavigate } from "react-router-dom";
import { MdNavigateNext } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";
import { MdContentCopy } from "react-icons/md";

import { HiMagnifyingGlass } from "react-icons/hi2";
import InputGroup from "react-bootstrap/InputGroup";
import { Ucontext } from "../context/Ucontext";
import Loading from "../components/loader/Loading";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import {
  adminLogin,
  updateBulkUserInfo,
  updateUserInfo,
} from "../context/functions/AdminFunction";
import { REACT_APP_URL } from "../config";
import { toast } from "react-toastify";
import moment from "moment";
import { IoIosRefresh } from "react-icons/io";
import { haveValue } from "../utils/common";
import ReactPaginate from "react-paginate";
import { constants } from "../utils/constants";

function EditModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="edit_form_details">
          <Form>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicText">
                  <Form.Label>Offer/ Brand Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter Name" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicText">
                  <Form.Label>Discount</Form.Label>
                  <Form.Control type="number" placeholder="Offer %" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicText">
                  <Form.Label>Description</Form.Label>
                  <Form.Control type="text" placeholder="Enter Description" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicText">
                  <Form.Label>Description</Form.Label>
                  <Form.Control type="text" placeholder="Enter Description" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicNumber">
                  <Form.Label>Discount</Form.Label>
                  <Form.Control type="number" placeholder="Offer %" />
                </Form.Group>
              </Col>
            </Row>
            <Row md={10}>
              <Button variant="danger" type="submit">
                Update
              </Button>
            </Row>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const Landlord = () => {
  const {
    adminDispatch,
    adminState,
    getAllLandlords,
    getAllProperties,
    authState,
    getLandlordProperties,
  } = useContext(Ucontext);
  const { user: adminUser } = authState;

  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);

  const { totalLandlords, totalProperties } = adminState;

  const [sortColumn, setSortColumn] = useState(null);
  const [sortType, setSortType] = useState("");
  const [EditShow, setEditShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [landlordList, setLandlordList] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [companyStatusLandlord, setCompanyStatusLandlord] = useState({});
  const [otpLandlord, setOtpLandlord] = useState({});
  const [showOtp, setShowOtp] = useState(false);

  const showOtpModal = (tenant) => {
    setOtpLandlord(tenant);
    setShowOtp(true);
  };

  // const [sortBy, setSortBy] = useState('Latest')
  const [filter, setFilter] = useState({
    role: "",
    isLandlordInComp: "",
  });

  useEffect(() => {
    loadLandlordsAndProperties(
      currentPage,
      searchVal,
      sortColumn,
      filter,
      sortType
    );
  }, [currentPage, searchVal, sortColumn, filter, sortType]);
  useEffect(() => {
    console.log(queryParameters, "CHECK queryParameters?.email");
    if (haveValue(queryParameters.get("email"))) {
      setSearchVal(queryParameters.get("email"));
      navigate("/landlord");
    }
  });

  const loadLandlordsAndProperties = async (
    currentPage,
    searchVal,
    sortColumn,
    filter,
    sortType
  ) => {
    setLoading(true);
    const { role = "", isLandlordInComp = "" } = filter;

    let landlords = await getAllLandlords(
      currentPage,
      searchVal,
      sortColumn,
      role,
      isLandlordInComp,
      sortType
    );

    console.log("landlords", landlords);
    if (landlords.status == "Success") {
      adminDispatch({
        type: "LANDLORDS_LOADED",
        payload: landlords?.landlords,
      });
      setLandlordList(landlords?.landlords);
      setTotalPages(landlords?.totalPages);
    }
    let properties = await getAllProperties();
    if (properties.status == "success") {
      adminDispatch({
        type: "PROPERTIES_LOADED",
        payload: properties?.properties,
      });
    }
    setLoading(false);
  };

  let interval;
  const handleSearch = (e) => {
    clearTimeout(interval);
    interval = setTimeout(() => {
      setSearchVal(e.target.value);
      setcurrentPage(1);
    }, 500);
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0) {
      setcurrentPage(newPage);
    }
  };

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    setcurrentPage(event.selected + 1);
  };

  const handleClickFilter = (e) => {
    setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setcurrentPage(1);
  };

  const handleSorting = (name) => {
    if (name == sortColumn) {
      setSortType((prev) => (prev == "asc" ? "desc" : "asc"));
    } else {
      setSortType("asc");
    }
    console.log(name);
    setcurrentPage(1);
    setSortColumn(name);
  };

  const handleLogin = (user_id) => {
    const data = {
      user_id,
      user_type: "landlord",
    };
    adminLogin(data)
      .then((res) => {
        console.log(res, "CHECK THIS HERE");
        const queryParams = new URLSearchParams({
          token: res.token || "", // Append token
          usertype: "landlord" || "", // Append usertype
          is_verified: res.user.is_verified || true, // Append is_verified
          saveduserid: res.user._id || "", // Append saveduserid
          external_customer_id: res.user._id || "", // Append external_customer_id
          app_type: "skor" || "", // // Append app_type
        });
        window.open(
          new URL(`${REACT_APP_URL}/redirect?${queryParams.toString()}`).href,
          "_blank"
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeLandlordApproval = (landlord) => {
    var data = {
      is_approved: landlord?.is_approved == false ? true : false,
    };
    updateUserInfo(data, landlord?._id, "landlord")
      .then((res) => {
        toast.success("Status Updated");
        loadLandlordsAndProperties(
          currentPage,
          searchVal,
          sortColumn,
          filter,
          sortType
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Toggle select all rows
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(totalLandlords.map((item) => item._id));
    }
    setSelectAll(!selectAll);
  };

  // Toggle selection of a specific row
  const handleRowSelect = (id) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((rowId) => rowId !== id)
        : [...prevSelected, id]
    );
  };

  // Handle status update from dropdown
  const handleStatusUpdate = (status) => {
    if (selectedRows.length === 0) {
      alert("Please select at least one row to update.");
      return;
    }
    var data = { account_status: status };
    updateBulkUserInfo(data, selectedRows, "landlord")
      .then((res) => {
        setSelectedRows([]);
        setSelectAll(false);
        toast.success("Status Updated");
        loadLandlordsAndProperties(
          currentPage,
          searchVal,
          sortColumn,
          filter,
          sortType
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAccountStatus = (account_status) => {
    let status;
    switch (account_status) {
      case "pending":
        status = "Pending";
        break;
      case "approved":
        status = "Approved";
        break;
      case "not-approved":
        status = "Not Approved";
        break;
      default:
        status = "Pending";
    }

    return status;
  };

  const openChangeCompanyStatus = (landlord) => {
    setShowCompanyModal(true);
    setCompanyStatusLandlord(landlord);
  };

  const handleChangeCompanyStatus = () => {
    var data = {
      isLandlordInComp:
        haveValue(companyStatusLandlord?.isLandlordInComp) &&
        companyStatusLandlord?.isLandlordInComp != "individual"
          ? "individual"
          : "company",
    };
    updateUserInfo(data, companyStatusLandlord?._id, "landlord")
      .then((res) => {
        setShowCompanyModal(false);
        if (res.user?.isLandlordInComp == "individual") {
          loadLandlordsAndProperties(
            currentPage,
            searchVal,
            sortColumn,
            filter,
            sortType
          );
        } else {
          navigate(
            `/landlorddetail/${companyStatusLandlord?._id}?editCompany=true`
          );
        }
        console.log(res, "check the response for updatedlandlord");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function ChangeCompanyStatusModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="model-box-width"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Change Company Status
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="edit_form_details">
            <Form>
              <Row>
                <Col>
                  <p>
                    Are you sure you want to change the status to
                    {haveValue(companyStatusLandlord?.isLandlordInComp) &&
                    companyStatusLandlord?.isLandlordInComp != "individual"
                      ? " No"
                      : " Yes. Some additional data will be required"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Button
                  className="company-status-chnage-update-btn"
                  type="button"
                  onClick={handleChangeCompanyStatus}
                >
                  Update
                </Button>
              </Row>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <>
      <div className="landlord-main">
        <div className="landlord-header-box">
          <div className="header_table d-flex justify-content-between align-items-center">
            <h1>Landlord/ Agents</h1>
          </div>

          <div className="d-flex justify-content-around lanloard-admin-filter">
            <div className="landloard-filter-box">
              {loading ? <Loading /> : null}
              <div className="d-flex justify-content-start ">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    disabled={selectedRows.length === 0}
                  >
                    Update Status
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => handleStatusUpdate("pending")}
                      disabled={selectedRows.length === 0}
                    >
                      Pending
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => handleStatusUpdate("approved")}
                      disabled={selectedRows.length === 0}
                    >
                      Approved
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => handleStatusUpdate("not-approved")}
                      disabled={selectedRows.length === 0}
                    >
                      Not Approved
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            <div className="searchbar_table landloard-filter-box">
              <div>
                <h5>Landlord/Agent</h5>
                {/* <select name="role" id="role" onChange={handleClickFilter}>
                  <option value="">All</option>
                  <option value="yes">Landlord</option>
                  <option value="no">Agent</option>
                </select> */}
                <Form.Select onChange={handleClickFilter} name="role" id="role">
                  <option value="">All</option>
                  <option value="yes">Landlord</option>
                  <option value="no">Agent</option>
                </Form.Select>
              </div>
              <div>
                <h5>Is Institution</h5>
                {/* <select
                  name="isLandlordInComp"
                  id="isLandlordInComp"
                  onChange={handleClickFilter}
                >
                  <option value="">All</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select> */}
                <Form.Select
                  name="isLandlordInComp"
                  id="isLandlordInComp"
                  onChange={handleClickFilter}
                >
                  <option value="">All</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </Form.Select>
              </div>
              <div>
                <InputGroup className="input_table_header ">
                  <InputGroup.Text id="basic-addon1">
                    <HiMagnifyingGlass />
                  </InputGroup.Text>
                  <Form.Control
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="basic-addon1"
                    // value={searchVal}
                    defaultValue={
                      haveValue(queryParameters.get("email"))
                        ? queryParameters.get("email")
                        : ""
                    }
                    onChange={handleSearch}
                  />
                </InputGroup>
              </div>
              {/* <OverlayTrigger trigger="click" placement="bottom" overlay={filter1()}>
            <button>
              <GiSettingsKnobs size={22} color='var(--dark)' />
            </button>
          </OverlayTrigger> */}
            </div>
          </div>
        </div>

        <Table responsive hover>
          <thead className="landloar-tabel-head">
            <tr>
              <th>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
              </th>
              <th onClick={(e) => handleSorting("fname_companyName")}>
                Name{" "}
                {sortColumn == "fname_companyName" ? (
                  sortType == "asc" ? (
                    <FaSortUp />
                  ) : (
                    <FaSortDown />
                  )
                ) : (
                  <FaSort />
                )}
              </th>
              <th onClick={(e) => handleSorting("fname_companyName")}>IBAN</th>
              <th onClick={(e) => handleSorting("cif_dni_nie")}>
                NIF/DNI/NIE{" "}
                {sortColumn == "cif_dni_nie" ? (
                  sortType == "asc" ? (
                    <FaSortUp />
                  ) : (
                    <FaSortDown />
                  )
                ) : (
                  <FaSort />
                )}
              </th>
              <th>Landlord/Agent</th>
              <th>Is Institution?</th>
              <th onClick={(e) => handleSorting("property_count")}>
                No. of Properties{" "}
                {sortColumn == "property_count" ? (
                  sortType == "asc" ? (
                    <FaSortUp />
                  ) : (
                    <FaSortDown />
                  )
                ) : (
                  <FaSort />
                )}
              </th>
              <th>Status </th>
              <th>Created At </th>
              {/* <th> </th> */}
              <th> </th>
            </tr>
          </thead>
          <tbody className="landloar-tabel-head">
            {totalLandlords &&
              totalLandlords.map((landlord, index) => (
                <tr key={landlord?._id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedRows.includes(landlord?._id)}
                      onChange={() => handleRowSelect(landlord?._id)}
                    />
                  </td>
                  <td>
                    {landlord?.fname_companyName} {landlord?.lname}
                  </td>
                  <td>{landlord?.iban_num}</td>
                  <td>{landlord?.cif_dni_nie}</td>
                  <td>{landlord?.landlordtype}</td>
                  <td>
                    <a
                      href={null}
                      onClick={(e) => {
                        adminUser?.role == constants.ADMIN ||
                        adminUser?.role == constants.SUPER_ADMIN
                          ? openChangeCompanyStatus(landlord)
                          : console.log("NOT ALLOWED FOR THIS ROLE");
                      }}
                    >
                      {haveValue(landlord?.isLandlordInComp) &&
                      landlord?.isLandlordInComp != "individual"
                        ? "Yes"
                        : "No"}
                    </a>
                  </td>
                  <td>{landlord?.properties.length}</td>
                  <td>{getAccountStatus(landlord?.account_status)}</td>
                  <td>{moment(landlord?.createdAt).format("DD/MM/YYYY")}</td>
                  {/* <td>
                  <NavLink
                    to={null}
                    onClick={(e) => changeLandlordApproval(landlord)}
                  >
                    Mark{" "}
                    {landlord?.is_approved == false
                      ? "Approved"
                      : "Not Approved"}
                  </NavLink>
                </td> */}

                  <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        as={Button}
                        id="dropdown-custom-components"
                        size="sm"
                        className="lanloard-admin-head-btn"
                      >
                        More
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {adminUser?.role == constants.SUPER_ADMIN && (
                          <Dropdown.Item
                            as="button"
                            onClick={(e) => {
                              e.preventDefault();
                              handleLogin(landlord?._id);
                            }}
                          >
                            Login as landlord
                          </Dropdown.Item>
                        )}
                        <Dropdown.Item
                          as="button"
                          onClick={(e) =>
                            navigate(`/landlorddetail/${landlord?._id}`)
                          }
                        >
                          Details
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="button"
                          onClick={(e) => showOtpModal(landlord)}
                        >
                          Show OTP
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    {/* <OverlayTrigger
                    key={landlord.id}
                    trigger="click"
                    placement="left"
                    overlay={
                      <Popover id={`popover-${landlord?._id}`}>
                        <Popover.Body>
                          <div className="popover_buttons">
                            <button
                              style={{ color: "var(--dark)" }}
                              onClick={(e) => {
                                e.preventDefault();
                                handleLogin(landlord?._id);
                              }}
                            >
                              Login as landlord
                            </button>
                          </div>
                          <div className="popover_buttons">
                            <button
                              style={{
                                textDecoration: "none",
                                color: "var(--dark)",
                              }}
                              onClick={(e) =>
                                navigate(`/landlorddetail/${landlord?._id}`)
                              }
                            >
                              Details
                            </button>
                          </div>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <button className="action-btn" type="button">
                      ...
                    </button>
                  </OverlayTrigger> */}
                  </td>
                </tr>
              ))}
            {totalLandlords?.length === 0 && (
              <tr>
                <td colSpan={10} className="">
                  No Data Found!
                </td>
              </tr>
            )}
            <EditModal show={EditShow} onHide={() => setEditShow(false)} />
            <ChangeCompanyStatusModal
              show={showCompanyModal}
              onHide={(e) => setShowCompanyModal(false)}
            />
          </tbody>
        </Table>
        <ReactPaginate
          nextLabel={<MdNavigateNext />}
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={totalPages}
          previousLabel={<GrFormPrevious />}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
          forcePage={currentPage - 1}
        />
        {/* <div className="">
          <button
            onClick={() => handlePageChange(+currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span>{`Page ${currentPage} of ${totalPages}`}</span>
          <button
            onClick={() => handlePageChange(+currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div> */}
      </div>
      <Modal
        show={showOtp}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={(e) => {
          setShowOtp(false);
          setOtpLandlord({});
        }}
      >
        <Modal.Header closeButton className="model-header-box">
          <Modal.Title id="contained-modal-title-vcenter">
            OTP for {otpLandlord?.fname || ""} {otpLandlord?.lname || ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="edit_form_details otp-main-box">
            <p className="otp-edit-box">{otpLandlord?.otp || ""}</p>
            <div className="copy-icon-box">
              <MdContentCopy />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Landlord;

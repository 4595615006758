import React, { useEffect, useState, useContext } from "react";
import Table from "react-bootstrap/esm/Table";
import { loadTransactions } from "../context/functions/AdminFunction";
import { Form, InputGroup, Button } from "react-bootstrap";
import { FaFileDownload } from "react-icons/fa";
import { HiMagnifyingGlass } from "react-icons/hi2";
import CsvDownloadButton from "react-json-to-csv";
import moment from "moment";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import Loading from "../components/loader/Loading";
import { MdNavigateNext } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";
import ReactPaginate from "react-paginate";

let interval = null;
const TransactionsAssigned = (props) => {
  const [transactions, setTransactions] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortType, setSortType] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getTransactionsFunc();
  }, [currentPage, searchVal, sortColumn, sortType]);

  const getTransactionsFunc = async () => {
    setLoading(true);
    const temp = await loadTransactions(
      "assignedProps",
      searchVal,
      currentPage,
      sortType,
      sortColumn
    );

    setTotalPages(temp?.totalPages);
    setTransactions(temp?.transactions);
    setLoading(false);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    debouncedSearch(value);
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0) {
      setcurrentPage(newPage);
    }
  };

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    setcurrentPage(event.selected + 1);
  };

  const debouncedSearch = (value) => {
    clearTimeout(interval);
    interval = setTimeout(() => {
      setSearchVal(value);
      setcurrentPage(1);
    }, 500);
  };

  const handleSorting = (name) => {
    if (name == sortColumn) {
      setSortType((prev) => (prev == "asc" ? "desc" : "asc"));
    } else {
      setSortType("asc");
    }
    console.log(name);
    setcurrentPage(1);
    setSortColumn(name);
  };

  const AddressContent = ({ content }) => {
    // Set the character limit
    const charLimit = 20;

    // State to toggle between showing full and truncated content
    const [isExpanded, setIsExpanded] = useState(false);

    // Function to toggle between "show more" and "show less"
    const toggleContent = () => setIsExpanded(!isExpanded);

    return (
      <div>
        {/* Show truncated content if not expanded, otherwise show full content */}
        <p>
          {isExpanded
            ? content
            : `${content.slice(0, charLimit)}${
                content.length > charLimit ? "..." : ""
              }`}
          {content.length > charLimit && (
            <Button
              onClick={toggleContent}
              variant="link"
              style={{ fontSize: "12px", display: "inline" }}
            >
              {isExpanded ? "show less" : "show more"}
            </Button>
          )}
        </p>
      </div>
    );
  };

  return (
    <>
      {loading ? <Loading /> : null}
      <div className="transaction-main">
        <div className="header_table d-flex justify-content-between align-items-center">
          <h1>Transactions</h1>
        </div>
        <div className="searchbar_table mb-3">
          <div className="d-flex align-items-center gap-4">
            <InputGroup className="input_table_header">
              <InputGroup.Text id="basic-addon1">
                <HiMagnifyingGlass />
              </InputGroup.Text>
              <Form.Control
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon1"
                onChange={handleSearch}
              />
            </InputGroup>
          </div>
        </div>
        <Table responsive hover className="transaction-rental">
          <thead className="transactionassigned-admin-head">
            <tr>
              <th>Landlord Name</th>
              <th>Tenant Name</th>
              <th>LL Email</th>
              <th>LL IBAN Number</th>
              <th>Address</th>
              <th>Amount</th>
              <th>Payment Method</th>
              <th>Transaction Id</th>
              <th>Type</th>
              <th
                name="created_at"
                onClick={(e) => handleSorting("created_at")}
              >
                Payment Date{" "}
                {sortColumn == "created_at" ? (
                  sortType == "asc" ? (
                    <FaSortUp />
                  ) : (
                    <FaSortDown />
                  )
                ) : (
                  <FaSort />
                )}
              </th>
              <th
                name="processing_date"
                onClick={(e) => handleSorting("processing_date")}
              >
                Payment For{" "}
                {sortColumn == "processing_date" ? (
                  sortType == "asc" ? (
                    <FaSortUp />
                  ) : (
                    <FaSortDown />
                  )
                ) : (
                  <FaSort />
                )}
              </th>
            </tr>
          </thead>
          <tbody className="transactionassigned-admin-head">
            {transactions &&
              transactions.map((val, index) => (
                <tr key={index}>
                  <td>
                    {val.landlord.fname_companyName || ""}{" "}
                    {val.landlord.lname || ""}
                  </td>
                  <td>
                    {val.tenant.fname || ""} {val.tenant.lname || ""}
                  </td>
                  <td>{val?.landlord?.email}</td>
                  <td>{val?.landlord?.iban_num}</td>
                  <td className="address-content">
                    <AddressContent content={val.address} />
                  </td>
                  <td>{val.amount}</td>
                  <td>{val?.payment_method}</td>
                  <td>{val?.transaction_id}</td>
                  <td>{val.type}</td>
                  <td>
                    {val.created_at &&
                      moment(val.created_at).format("YYYY/MM/DD")}
                  </td>
                  <td>
                    {val.processing_date &&
                      moment(val.processing_date).format("YYYY/MM/DD")}
                  </td>
                </tr>
              ))}
            {transactions?.length === 0 && (
              <tr>
                <td colSpan={4} className="">
                  No Data Found!
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        {/* <div className="">
          <button
            onClick={() => handlePageChange(+currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span>{`Page ${currentPage} of ${totalPages}`}</span>
          <button
            onClick={() => handlePageChange(+currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div> */}
        <ReactPaginate
          nextLabel={<MdNavigateNext />}
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={totalPages}
          previousLabel={<GrFormPrevious />}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
          forcePage={currentPage - 1}
        />
      </div>
    </>
  );
};

export default TransactionsAssigned;

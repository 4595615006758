import React, { useEffect, useState, useContext } from "react";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import { saveAs } from "file-saver";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { AiFillStar } from "react-icons/ai";
import { MdContentCopy } from "react-icons/md";

import { ImBin } from "react-icons/im";
import { IoIosRefresh } from "react-icons/io";
import {
  InputGroup,
  Dropdown,
  Popover,
  OverlayTrigger,
  Form,
} from "react-bootstrap";
import Loading from "../loader/Loading";
import { Ucontext } from "../../context/Ucontext";
import axios from "axios";
import { BASE_URL, REACT_APP_URL } from "../../config/index";
import { useNavigate } from "react-router-dom";
import {
  adminLogin,
  downloadDocuSignDoc,
  downloadTinkReport,
  getTenantKycDocs,
  updateUserInfo,
} from "../../context/functions/AdminFunction";
import moment from "moment";
import { getClassName, haveValue } from "../../utils/common";
import { toast } from "react-toastify";
import { constants } from "../../utils/constants";

const TenantsList = (props) => {
  const {
    tenants,
    sortColumn,
    setSortColumn,
    sortType,
    setSortType,
    handleSorting,
    loadTenants,
    currentPage,
    searchVal,
    sortBy,
    filter,
  } = props;

  const navigate = useNavigate();

  const { adminDispatch, getSingleUser, adminState, getAllTenants, authState } =
    useContext(Ucontext);
  const { totalTenants, totalProperties } = adminState;
  const { user: adminUser } = authState;

  const [RentModal, setRentModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [singleTenant, setSingleTenant] = useState();
  const [otpTenant, setOtpTenant] = useState({});
  const [showOtp, setShowOtp] = useState(false);

  const showOtpModal = (tenant) => {
    setOtpTenant(tenant);
    setShowOtp(true);
  };

  const handleDownloadCsv = (tenant_id) => {
    setLoading(true);
    downloadTinkReport(tenant_id)
      .then((response) => {
        // Extract the filename from Content-Disposition
        const blob = new Blob([response.data], { type: "text/csv" });

        // Extract filename from Content-Disposition header
        const contentDisposition = response.headers["content-disposition"];
        const filename = contentDisposition
          ? contentDisposition.split("filename=")[1]?.replace(/"/g, "")
          : `${tenant_id}-tink-report.csv`;

        // Save the file
        saveAs(blob, filename);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "Error in handleDownloadCsv");
        setLoading(false);
      });
  };

  function MyVerticallyCenteredModal(props) {
    if (loading) return <Loading />;
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            User Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="user_details">
            <Row>
              <Col>
                <p>Name</p>
                <h5>
                  {singleTenant?.fname} {singleTenant?.lname}
                </h5>
              </Col>
              <Col>
                <p>DNI/NIE</p>
                <h5>{singleTenant?.dni_nie}</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>Type Of Employee:</p>
                <h5>{singleTenant?.type_of_employee}</h5>
              </Col>
              <Col>
                <p>Nationality:</p>
                <h5>{singleTenant?.nationality}</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>Name Of Company:</p>
                <h5>{singleTenant?.name_of_company}</h5>
              </Col>
              <Col>
                <p>Employment Status:</p>
                <h5>{singleTenant?.employment_status}</h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>University:</p>
                <h5>{singleTenant?.name_of_university}</h5>
              </Col>
              <Col>
                <p>Education:</p>
                <h5>{singleTenant?.type_of_student}</h5>
              </Col>
            </Row>
            {singleTenant &&
              singleTenant.previousFeedback &&
              singleTenant.previousFeedback.rating && (
                <Row>
                  <Col>
                    <p>Previous Landlord Rating:</p>
                    <h5 style={{ display: "flex", alignItems: "center" }}>
                      {singleTenant.previousFeedback.rating}
                      <AiFillStar color="#F59F18" size={20} />
                    </h5>
                  </Col>
                </Row>
              )}
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  const loadSingleTenant = (userid, type) => {
    console.log("Load single user runs");
    const tenantRes = getSingleUser(userid, type);
    console.log(tenantRes);
  };

  const getKycStatus = (value) => {
    if (value) {
      return <p style={{ color: "green" }}>Yes</p>;
    } else {
      return <p style={{ color: "red" }}>No</p>;
    }
  };

  const handleLogin = async (value) => {
    const data = {
      user_id: value._id,
      user_type: "tenant",
    };
    adminLogin(data)
      .then((res) => {
        console.log(res, "CHECK THIS HERE");
        const queryParams = new URLSearchParams({
          token: res.token || "", // Append token
          usertype: "tenant" || "", // Append usertype
          is_verified: res.user.is_verified || true, // Append is_verified
          saveduserid: res.user._id || "", // Append saveduserid
          external_customer_id: res.user._id || "", // Append external_customer_id
          app_type: "skor" || "", // // Append app_type
        });
        console.log(
          new URL(`${REACT_APP_URL}/redirect?${queryParams.toString()}`).href,
          "CHECK HREF HEARE"
        );
        window.open(
          new URL(`${REACT_APP_URL}/redirect?${queryParams.toString()}`).href,
          "_blank"
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const viewDetail = async (value) => {
    const axiosRes = await axios({
      method: "POST",
      headers: { "x-access-token": localStorage.getItem("token") },
      url: `${BASE_URL}/users/getAllData?type=tenant`,
      data: {
        user_id: value?._id,
      },
    });
    console.log(axiosRes?.data?.tenant, "tenatn data here");
    navigate("/scoreProfile", { state: axiosRes?.data?.tenant });
  };

  const viewDocument = async (docId) => {
    const formdata = {
      envelopeId: docId,
    };
    downloadDocuSignDoc(formdata)
      .then((res) => {
        console.log(res.zipFile, "Response for download document");

        var binaryString = atob(res.zipFile);
        var len = binaryString.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        // Create blob from Uint8Array
        var blob = new Blob([bytes.buffer], { type: "application/pdf" });

        // Create a temporary link element
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "document.pdf";

        // Trigger the download
        link.click();
      })
      .catch((err) => {
        console.log(err, "Error while downloading docusign doc");
      });
  };

  const checkIeDetails = (userDetails) => {
    var check = true;
    console.log(
      userDetails,
      "userDetails?.ie_details_iduserDetails?.ie_details_id"
    );
    if (!userDetails?.ie_details_id[0]) {
      check = false;
      console.log("Failing in no entry");
    }
    if (
      userDetails?.ie_student_id != userDetails?.ie_details_id[0]?.student_id
    ) {
      check = false;
      console.log("Failing in studentid");
    }

    return check;
  };

  const calculateTenantScore = (tenant) => {
    // tenant?.paymentScores?.creditScore?.value
    var result = 0;
    if (tenant?.gurantor_data[0]?.paymentScores) {
      result = tenant?.gurantor_data[0]?.paymentScores?.creditScore?.value;
    } else {
      if (checkIeDetails(tenant)) {
        var totalScore = (tenant?.paymentScores?.creditScore?.value || 0) / 2;

        if (tenant?.is_university) {
          if (totalScore >= 600) {
            totalScore += 200;
          } else {
            totalScore += 400;
          }
        }
        if (totalScore > 850) {
          totalScore = 850;
        }
        result = totalScore;
      } else {
        if (tenant?.paymentScores?.creditScore?.value) {
          result = tenant?.paymentScores?.creditScore?.value;
        }
      }
    }
    return result;
  };

  const handleTenantDelete = (tenant) => {
    var data = {
      status: tenant?.status == false ? true : false,
    };
    updateUserInfo(data, tenant?._id, "tenant")
      .then((res) => {
        toast.success("Status Updated");
        loadTenants(currentPage, searchVal, sortBy, filter);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Table responsive hover>
        <thead className="tenats-list-admin-head">
          <tr>
            <th name="fname" onClick={(e) => handleSorting("fname")}>
              Name{" "}
              {sortColumn == "fname" ? (
                sortType == "asc" ? (
                  <FaSortUp />
                ) : (
                  <FaSortDown />
                )
              ) : (
                <FaSort />
              )}
            </th>
            <th name="dni_nie" onClick={(e) => handleSorting("dni_nie")}>
              DNI/NIE{" "}
              {sortColumn == "dni_nie" ? (
                sortType == "asc" ? (
                  <FaSortUp />
                ) : (
                  <FaSortDown />
                )
              ) : (
                <FaSort />
              )}
            </th>
            <th
              name="passport_id"
              onClick={(e) => handleSorting("passport_id")}
            >
              Passport{" "}
              {sortColumn == "passport_id" ? (
                sortType == "asc" ? (
                  <FaSortUp />
                ) : (
                  <FaSortDown />
                )
              ) : (
                <FaSort />
              )}
            </th>
            <th
              name="nationality"
              onClick={(e) => handleSorting("nationality")}
            >
              Nationality{" "}
              {sortColumn == "nationality" ? (
                sortType == "asc" ? (
                  <FaSortUp />
                ) : (
                  <FaSortDown />
                )
              ) : (
                <FaSort />
              )}
            </th>
            <th
              name="credit_score"
              onClick={(e) => handleSorting("credit_score")}
            >
              Score{" "}
              {sortColumn == "credit_score" ? (
                sortType == "asc" ? (
                  <FaSortUp />
                ) : (
                  <FaSortDown />
                )
              ) : (
                <FaSort />
              )}
            </th>
            <th>Joined From</th>
            <th>Digital Verification</th>
            <th>Bank Verified</th>
            <th
              name="tenant_docs_count"
              onClick={(e) => handleSorting("tenant_docs_count")}
            >
              Docs Uploaded{" "}
              {sortColumn == "tenant_docs_count" ? (
                sortType == "asc" ? (
                  <FaSortUp />
                ) : (
                  <FaSortDown />
                )
              ) : (
                <FaSort />
              )}
            </th>
            <th>Have Guarantor</th>
            <th>Guarantor Scored</th>
            <th>Agreement Signed</th>
            <th>Joined On</th>
            <th>Status</th>
            <th>Tink Report</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody className="tenats-list-admin-head">
          {console.log(tenants)}
          {tenants.map((tenant, index) => (
            <tr key={index}>
              <td>
                {tenant?.fname} {tenant?.lname}
              </td>
              <td>{tenant?.dni_nie}</td>
              <td>{tenant?.passport_id}</td>
              <td>{tenant?.nationality}</td>
              <td>{calculateTenantScore(tenant)}</td>
              <td>{tenant?.referredName || "skor"}</td>
              <td>{getKycStatus(tenant?.onfido_kyc_success)}</td>
              <td>{getKycStatus(tenant?.paymentScores)}</td>
              <th>{tenant?.tenant_docs.length}</th>
              <th>{getKycStatus(tenant?.have_gurantor)}</th>
              <th>
                {tenant?.gurantor_data &&
                tenant?.gurantor_data[0] &&
                tenant?.gurantor_data[0]?.paymentScores?.creditScore?.value
                  ? getKycStatus(
                      tenant?.gurantor_data[0]?.paymentScores?.creditScore
                        ?.value
                    )
                  : getKycStatus()}
              </th>
              <td>
                {tenant?.docSigned == true &&
                tenant?.docuSignResponse == "completed" ? (
                  <a onClick={() => viewDocument(tenant?.docuSignEnvelopeId)}>
                    Download
                  </a>
                ) : (
                  "No"
                )}
              </td>
              <td>{moment(tenant?.created_at).format("DD/MM/YYYY")}</td>
              <td
                className={getClassName(tenant.status == false ? false : true)}
              >
                {tenant.status == false ? "In-Active" : "Active"}
              </td>
              <td>
                {" "}
                <a
                  onClick={() =>
                    haveValue(tenant?.paymentScores)
                      ? handleDownloadCsv(tenant?._id)
                      : console.log("NOTING")
                  }
                >
                  {haveValue(tenant?.paymentScores)
                    ? "Download"
                    : "Not Available"}
                </a>
              </td>
              <td>
                <a onClick={() => viewDetail(tenant)}>View Details</a>
              </td>
              <td>
                <Dropdown>
                  <Dropdown.Toggle
                    as={Button}
                    id="dropdown-custom-components"
                    size="sm"
                    className="lanloard-admin-head-btn"
                  >
                    More
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {adminUser?.role == constants.SUPER_ADMIN && (
                      <>
                        <Dropdown.Item
                          as="button"
                          onClick={(e) => {
                            e.preventDefault();
                            handleLogin(tenant);
                          }}
                        >
                          Login as tenant
                        </Dropdown.Item>
                        <Dropdown.Item
                          as="button"
                          onClick={(e) => handleTenantDelete(tenant)}
                        >
                          <ImBin /> &nbsp; Delete
                        </Dropdown.Item>
                      </>
                    )}
                    <Dropdown.Item
                      as="button"
                      onClick={(e) => showOtpModal(tenant)}
                    >
                      <IoIosRefresh /> &nbsp; Show OTP
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                {/* <OverlayTrigger
                  trigger="click"
                  placement="left"
                  overlay={
                    <Popover id="popover-basic">
                      <Popover.Body>
                        <div className="popover_buttons">
                          <button onClick={(e) => showOtpModal(tenant)}>
                            <IoIosRefresh /> &nbsp; Show OTP
                          </button>
                          <button
                            style={{ color: "var(--dark)" }}
                            onClick={(e) => handleTenantDelete(tenant)}
                          >
                            <ImBin /> &nbsp; Delete
                          </button>
                        </div>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <Button variant="outline-danger" href="#">
                    ...
                  </Button>
                </OverlayTrigger> */}
              </td>
            </tr>
          ))}
          {tenants?.length === 0 && (
            <tr>
              <td colSpan={16} className="">
                No Data Found!
              </td>
            </tr>
          )}
          <MyVerticallyCenteredModal
            show={RentModal}
            onHide={() => setRentModal(false)}
          />
        </tbody>
      </Table>

      <Modal
        show={showOtp}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={(e) => {
          setShowOtp(false);
          setOtpTenant({});
        }}
      >
        <Modal.Header closeButton className="model-header-box">
          <Modal.Title id="contained-modal-title-vcenter">
            OTP for {otpTenant?.fname || ""} {otpTenant?.lname || ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="edit_form_details otp-main-box">
            <p className="otp-edit-box">{otpTenant?.otp || ""}</p>
            <div className="copy-icon-box">
              <MdContentCopy />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TenantsList;
